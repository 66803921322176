import React from "react";

function Logoff() {
    return (
        <svg width="26" height="24" viewBox="0 0 26 24" fill="none">
            <path
                d="M19.5953 6.97197L19.6032 6.9801L19.6112 6.98809L24.411 11.7879C24.4111 11.788 24.4111 11.788 24.4112 11.7881C24.4673 11.8443 24.4989 11.9206 24.4989 12C24.4989 12.0795 24.4673 12.1558 24.4112 12.212C24.4111 12.2121 24.4111 12.2121 24.411 12.2121L19.6223 17.0009C19.5663 17.0526 19.4926 17.081 19.4163 17.0804C19.3376 17.0797 19.2624 17.0481 19.2067 16.9925C19.1511 16.9369 19.1196 16.8617 19.1189 16.783C19.1182 16.7066 19.1467 16.633 19.1984 16.577L21.2561 14.5193L23.4753 12.3H20.3368H8.19922C8.11965 12.3 8.04335 12.2684 7.98709 12.2122C7.93083 12.1559 7.89922 12.0796 7.89922 12C7.89922 11.9205 7.93083 11.8442 7.98709 11.7879C8.04335 11.7317 8.11965 11.7 8.19922 11.7H20.3368H23.4753L21.2561 9.48081L19.1873 7.41201L19.1793 7.40402L19.1711 7.39617C19.1425 7.3685 19.1196 7.33539 19.1039 7.2988C19.0882 7.26219 19.0799 7.22283 19.0796 7.183C19.0792 7.14317 19.0868 7.10366 19.1019 7.06679C19.117 7.02992 19.1393 6.99642 19.1674 6.96826C19.1956 6.94009 19.2291 6.91781 19.266 6.90273C19.3028 6.88764 19.3423 6.88005 19.3822 6.8804C19.422 6.88075 19.4614 6.88902 19.498 6.90474C19.5346 6.92047 19.5677 6.94332 19.5953 6.97197ZM1.58709 2.18792C1.64335 2.13166 1.71965 2.10005 1.79922 2.10005C1.87878 2.10005 1.95509 2.13166 2.01135 2.18792C2.06761 2.24418 2.09922 2.32048 2.09922 2.40005V21.6C2.09922 21.6796 2.06761 21.7559 2.01135 21.8122C1.95509 21.8684 1.87879 21.9001 1.79922 21.9001C1.71965 21.9001 1.64335 21.8684 1.58709 21.8122C1.53083 21.7559 1.49922 21.6796 1.49922 21.6V2.40005C1.49922 2.32048 1.53083 2.24418 1.58709 2.18792Z"
                fill="#3F3F46"
                stroke="#03093D"
                strokeWidth="2.6"
            />
        </svg>
    );
}

export default Logoff;
