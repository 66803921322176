import React from "react";

function IconPhone() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M4.78105 4.78105C4.28095 5.28115 4 5.95942 4 6.66667V8C4 19.0453 12.9547 28 24 28H25.3333C26.0406 28 26.7189 27.719 27.219 27.219C27.719 26.7189 28 26.0406 28 25.3333V20.9613C28.0001 20.6814 27.912 20.4085 27.7483 20.1814C27.5846 19.9543 27.3536 19.7845 27.088 19.696L21.0973 17.6987C20.7929 17.5975 20.4621 17.6094 20.1658 17.7324C19.8695 17.8553 19.6274 18.081 19.484 18.368L17.9773 21.3773C14.7126 19.9022 12.0978 17.2874 10.6227 14.0227L13.632 12.516C13.919 12.3726 14.1447 12.1305 14.2676 11.8342C14.3906 11.5379 14.4025 11.2071 14.3013 10.9027L12.304 4.912C12.2156 4.64661 12.046 4.41574 11.8191 4.25207C11.5923 4.0884 11.3197 4.00021 11.04 4H6.66667C5.95942 4 5.28115 4.28095 4.78105 4.78105Z"
                stroke="#03093D"
                strokeWidth="2.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default IconPhone;
