import React from "react";

function WhitePrevArrow() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path
                d="M24.9999 31.6667L13.3333 20L24.9999 8.33337"
                stroke="white"
                strokeWidth="3.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default WhitePrevArrow;
