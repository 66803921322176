import React, { useEffect } from "react";
import Homebanner from "../../components/Homebanner/Homebanner";
import Nav from "../../components/Nav/Nav";
import Homegallary from "../../components/Homegallary/Homegallary";
import Abouthomepros from "../../components/Abouthomepros/Abouthomepros";
import Howwehelp from "../../components/Howwehelp/Howwehelp";
import Pronetwork from "../../components/Pronetwork/Pronetwork";
import Pagefooter from "../../components/Pagefooter/Pagefooter";
import Helpyou from "../../components/Helpyou/Helpyou";
import Testimonial from "../../components/Testimonial/Testimonial";
import Beforeafterslider from "../../components/Beforeafterslider/Beforeafterslider";
import Screen_Resize from "../../config/ScreenResize";

function Home() {
    useEffect(() => {
        Screen_Resize();
        
        // Body Class for Page
        const body = document.querySelector("body");
        document.body.classList.add("DarkHeader");
        return () => {
            body.classList.remove("DarkHeader");
        };
    }, []);

    return (
        <>
            <Nav />
            <div className="MainBody">
                <Homebanner />
                <Abouthomepros />
                <Homegallary />
                <Howwehelp url="/windows/form" />
                <Testimonial />
                <Beforeafterslider />
                <Helpyou />
                <Pronetwork />
                <Pagefooter />
            </div>
        </>
    );
}

export default Home;
