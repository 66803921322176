import React, { useEffect } from "react";
import Nav from "../../components/Nav/Nav";
import WindowQuiz from "../../components/WindowQuiz/WindowQuiz";
import Screen_Resize from "../../config/ScreenResize";

const Windows = () => {
    useEffect(() => {
        Screen_Resize();
    }, []);

    return (
        <>
            <Nav />
            <div className="MainBody">
                <WindowQuiz />
            </div>
        </>
    );
};

export default Windows;
