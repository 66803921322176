import React from "react";

function RightArrow() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8333 4.58423L19.25 11.0009M19.25 11.0009L12.8333 17.4176M19.25 11.0009H2.75" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
}

export default RightArrow;