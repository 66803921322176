import React from "react";

export function BASlide(props) {
    console.log(
        "propsbeforeImg",
        props.beforeImg,
        "props.afterImg",
        props.afterImg
    );
    return (
        <div className="slider-item" onClick={props.onClick}>
            <div className="beforeafter-block">
                <div className="beforeImgbox">
                    <div className="beforeimg">
                        <img src={props.beforeImg} alt={props.beforeImgName} />
                    </div>
                    <div className="tag">Before</div>
                </div>
                <div className="afterImgbox">
                    <div className="beforeimg">
                        <img src={props.afterImg} alt={props.afterImgName} />
                    </div>
                    <div className="tag">After</div>
                </div>
            </div>
        </div>
    );
}
