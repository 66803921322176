import { keyframes } from "@emotion/react";
export const customAnimation = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0px, 60px, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;
