import React from "react";

const RoofFormStep1 = () => {
    return (
        <div className="form">
            <div className="form-item">
                <div className="OpInput controlRadio">
                    <input type="radio" name="Q1YesNo" id="Q1Yes" />
                    <label htmlFor="Q1Yes">Yes</label>
                </div>
                <div className="OpInput controlRadio">
                    <input type="radio" name="Q1YesNo" id="Q1No" />
                    <label htmlFor="Q1No">No</label>
                </div>
            </div>
        </div>
    );
};
const RoofFormStep2 = () => {
    return (
        <div className="form">
            <div className="form-item">
                <div className="OpInput controlCheckbox">
                    <input type="checkbox" id="Q2Repair" />
                    <label htmlFor="Q2Repair">Repair</label>
                </div>
                <div className="OpInput controlCheckbox">
                    <input type="checkbox" id="Q2Replace" />
                    <label htmlFor="Q2Replace">Replace</label>
                </div>
                <div className="OpInput controlCheckbox">
                    <input type="checkbox" id="Q2Install" />
                    <label htmlFor="Q2Install">Install</label>
                </div>
            </div>
        </div>
    );
};

const RoofFormStep3 = () => {
    return (
        <div className="form">
            <div className="form-item">
                <div className="OpInput controlRadio">
                    <input type="radio" name="windowscount" id="Q31to10" />
                    <label htmlFor="Q31to10">Between 1-10</label>
                </div>
                <div className="OpInput controlRadio">
                    <input type="radio" name="windowscount" id="Q311to25" />
                    <label htmlFor="Q311to25">11 to 25</label>
                </div>
                <div className="OpInput controlRadio">
                    <input type="radio" name="windowscount" id="Q325plus" />
                    <label htmlFor="Q325plus">More than 25</label>
                </div>
            </div>
        </div>
    );
};

const RoofFormStep4 = () => {
    return (
        <div className="form">
            <div className="form-item">
                <div className="OpInput controlRadio">
                    <input
                        type="radio"
                        name="purchaseTFrame"
                        id="Q4Immediately"
                    />
                    <label htmlFor="Q4Immediately">Immediately</label>
                </div>
                <div className="OpInput controlRadio">
                    <input type="radio" name="purchaseTFrame" id="Q4Within6" />
                    <label htmlFor="Q4Within6">Within 6 months</label>
                </div>
                <div className="OpInput controlRadio">
                    <input type="radio" name="purchaseTFrame" id="Q4NotSure" />
                    <label htmlFor="Q4NotSure">Not Sure</label>
                </div>
            </div>
        </div>
    );
};

const RoofFormStep5 = () => {
    return (
        <div className="form">
            <div className="form-item">
                <input
                    type="text"
                    className="form-input"
                    name="Location1"
                    placeholder="Address 1"
                />
            </div>
            <div className="form-item">
                <input
                    type="text"
                    className="form-input"
                    name="Location2"
                    placeholder="Address 2"
                />
            </div>
        </div>
    );
};

const RoofFormStep6 = () => {
    return (
        <div className="form">
            <div className="form-item">
                <input
                    type="text"
                    className="form-input"
                    name="FirstName"
                    placeholder="First Name"
                />
            </div>
            <div className="form-item">
                <input
                    type="text"
                    className="form-input"
                    name="LastName"
                    placeholder="Last Name"
                />
            </div>
        </div>
    );
};

const RoofFormStep7 = () => {
    return (
        <div className="form">
            <div className="form-item invalid">
                <input
                    type="email"
                    className="form-input"
                    name="email"
                    placeholder="Your email"
                />
                <div className="msg-error">
                    Please enter your email address in format:
                    yourname@example.com
                </div>
            </div>
        </div>
    );
};

const RoofFormStep8 = () => {
    return (
        <div className="form">
            <div className="form-item">
                <input
                    type="phone"
                    className="form-input"
                    name="phone"
                    placeholder="Your phone number"
                />
                <div className="msg-info">
                    When you click Continue, you authorize companies on this
                    list to call and send pre-recorded and sms messages to phone
                    number above using an autodialer even if your phone number
                    is on any do not call list. Your consent here is not based
                    on a condition of purchase.
                </div>
            </div>
        </div>
    );
};

export {
    RoofFormStep1,
    RoofFormStep2,
    RoofFormStep3,
    RoofFormStep4,
    RoofFormStep5,
    RoofFormStep6,
    RoofFormStep7,
    RoofFormStep8,
};
