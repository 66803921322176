import React from "react";

function IconPencil() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M20.3093 6.97595L25.024 11.6906M22.3093 4.97595C22.9345 4.35075 23.7825 3.99951 24.6667 3.99951C25.5508 3.99951 26.3988 4.35075 27.024 4.97595C27.6492 5.60115 28.0004 6.44911 28.0004 7.33328C28.0004 8.21746 27.6492 9.06541 27.024 9.69062L8.66667 28.048H4V23.2853L22.3093 4.97595Z"
                stroke="#03093D"
                strokeWidth="2.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default IconPencil;
