import React from "react";
import { customAnimation } from "../../config/animation";
import Reveal from "react-awesome-reveal";

function PageHeroSec(props) {
    return (
        <div className="PageHeroSec">
            <div className="h-container">
                <Reveal duration={800} keyframes={customAnimation}>
                    <div className="CardBody">
                        <h1>{props.label}</h1>
                        <div className="secContent">
                            <h6>{props.label}</h6>
                            <h3 className="title">{props.title}</h3>
                            <p className="mb-3">{props.line1}</p>
                            <p>{props.line2}</p>
                        </div>
                        <div className="secImg">
                            <div className="WrapImg">
                                <img src={props.ImgUrl} alt={props.ImgAlt} />
                            </div>
                        </div>
                    </div>
                </Reveal>
            </div>
        </div>
    );
}

export default PageHeroSec;
