import React, { useEffect } from "react";
import Nav from "../../components/Nav/Nav";
import SolarQuiz from "../../components/SolarQuiz/SolarQuiz";
import Screen_Resize from "../../config/ScreenResize";

const SolarForm = () => {
    useEffect(() => {
        Screen_Resize();
    }, []);

    return (
        <>
            <Nav />
            <div className="MainBody">
                <SolarQuiz />
            </div>
        </>
    );
};

export default SolarForm;
