import React from "react";

function SliderArrowRight() {
  return (
   
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.55942 -9.80909e-07L14.1759 -4.29424e-07C14.6362 -4.09305e-07 15.073 0.209243 15.3693 0.571697L23.6339 10.6817C24.1297 11.2881 24.121 12.1763 23.6133 12.7723L15.3678 22.453C15.0717 22.8006 14.6443 23 14.1951 23L1.55942 23C0.698178 23 9.3339e-07 22.2816 9.72128e-07 21.3953L1.83721e-06 1.60465C1.87595e-06 0.718426 0.698175 -1.01856e-06 1.55942 -9.80909e-07Z" fill="#1CCA96"/>
    </svg>
    
    

  );
}

export default SliderArrowRight;