import React from "react";
import Modal from "react-modal";
import CloseIcon from "../Icons/CloseIcon";
import BtnCheckIcon from "../Icons/BtnCheckIcon";
import { FormItem } from "../common/FormItem";

function JoinNetwork({ closeModal, modalIsOpen }) {
    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Join Our Home Pro Network"
                overlayClassName="ModalForm"
            >
                <div className="ModalHeader">
                    <button className="CloseModal" onClick={closeModal}>
                        <CloseIcon />
                    </button>
                </div>
                <div className="JoinNetwork">
                    <h2>Join Our Home Pro Network</h2>
                    <p>Please, fill out the form</p>

                    <div className="form">
                        <FormItem
                            className=""
                            type="text"
                            name="FullName"
                            placeholder="Full Name"
                        />
                        <FormItem
                            className=""
                            type="email"
                            name="Email"
                            placeholder="Email"
                        />
                        <FormItem
                            className=""
                            type="phone"
                            name="PhoneNumber"
                            placeholder="Phone number"
                        />
                        <FormItem
                            className=""
                            type="text"
                            name="ZIP"
                            placeholder="ZIP code"
                        />
                        <FormItem
                            className=""
                            type="text"
                            name="Tnterest"
                            placeholder="What job are you interested in?"
                        />
                        <div className="form-item">
                            <select
                                class="form-select"
                                aria-label="Select Free Quote For"
                            >
                                <option selected>Select Quote For</option>
                                <option value="Windows">Windows</option>
                                <option value="Roofing">Roofing</option>
                                <option value="Solar">Solar</option>
                                <option value="Bathroom">Bathroom</option>
                            </select>
                        </div>

                        <div className="form-item">
                            <button className="primary-btn full filled">
                                <BtnCheckIcon /> SUBMIT
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default JoinNetwork;
