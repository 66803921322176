import React from "react";
import { customAnimation } from "../../config/animation";
import Reveal from "react-awesome-reveal";
import { Read } from "../Icons/index";
import { images } from "../../config/images";
import Servicecard from "../Servicecard/Servicecard";
import { useHistory } from "react-router-dom";

function Abouthomepros(props) {
    const history = useHistory();

    return (
        <div className={`Abouthomepros ${props.className}`}>
            <div className="h-container">
                <Reveal duration={800} keyframes={customAnimation}>
                    <div className="PageTile">
                        <h2 className="h2 text-center lg:text-left">
                            Helpful Home Pros Is The Easiest Solution
                        </h2>
                        <button
                            onClick={() => {
                                history.push("/aboutus");
                            }}
                            className=" primary-btn hideMobile big"
                        >
                            READ MORE ABOUT US
                        </button>
                    </div>
                </Reveal>
                <Reveal duration={800} keyframes={customAnimation}>
                    <div className="CardBody grid grid-cols-1 lg:grid-cols-2 lg:gap-16 lg:gap-y-20 gap-10">
                        <Servicecard
                            images={images.Illustration1}
                            cardHeader={"Quick, Quality Service"}
                            cardSub={
                                "Locate high-quality, licensed and insured contractors!"
                            }
                        />
                        <Servicecard
                            images={images.Illustration2}
                            cardHeader={"No Obligation, Fast Service"}
                            cardSub={
                                "Receive a no-obligation quote fast! Quotes are completely free."
                            }
                        />
                        <Servicecard
                            images={images.Illustration3}
                            cardHeader={"Competitive Quotes"}
                            cardSub={
                                "Many contractors offer special rebates and prices on projects."
                            }
                        />
                        <Servicecard
                            images={images.Illustration4}
                            cardHeader={
                                "We cover your home improvement needs like a superhero!"
                            }
                            cardSub={
                                "Put all the messy work on us! We will save time so you can relax!"
                            }
                        />
                    </div>
                </Reveal>
                <div className="Mobilebotton mt-10">
                    <button
                        className=" primary-btn big  desktophide"
                        type="button"
                    >
                        <Read /> READ MORE ABOUT US
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Abouthomepros;
