import React, { useState } from "react";
import BlueBoxItem from "../common/BlueBoxItem/BlueBoxItem";
import { customAnimation } from "../../config/animation";
import Reveal from "react-awesome-reveal";
import Pointer from "../Icons/Pointer";
import JoinNetwork from "../JoinNetwork/JoinNetwork";

function HappensNext() {
    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div className="HappensNext">
            <div className="h-container">
                <div className="helpBlocks">
                    <Reveal duration={800} keyframes={customAnimation}></Reveal>

                    <Reveal duration={800} keyframes={customAnimation}>
                        <div className="CardBody">
                            <div className="PageTile">
                                <h2>What happens next?</h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                </p>
                                <div className="lg:inline-block hidden">
                                    <button
                                        className="primary-btn big ghost btn-white filled"
                                        type="button"
                                        onClick={openModal}
                                    >
                                        <Pointer /> Join Our Home Pro Network
                                    </button>
                                </div>
                            </div>

                            <div className="PageContent">
                                <BlueBoxItem
                                    count={"1"}
                                    helpTitle={"Fill out our form"}
                                    description={
                                        "We collect information about your location, your project, and your home improvement needs."
                                    }
                                />
                                <BlueBoxItem
                                    count={"2"}
                                    helpTitle={"We help you find customers"}
                                    description={
                                        "Our expansive contractor network works with you to give personalized pricing quotes."
                                    }
                                />
                                <BlueBoxItem
                                    count={"3"}
                                    helpTitle={"Schedule an appointment"}
                                    description={
                                        "Book an appointment with a qualified contractor to review project details."
                                    }
                                />
                            </div>
                        </div>
                        <div className="block lg:hidden pl-6 mt-5 lg:mt-10">
                            <button
                                className="primary-btn big ghost btn-white filled"
                                type="button"
                            >
                                <Pointer /> Join Our Home Pro Network
                            </button>
                        </div>
                    </Reveal>
                </div>
            </div>
            {modalIsOpen && (
                <JoinNetwork
                    closeModal={closeModal}
                    modalIsOpen={modalIsOpen}
                />
            )}
        </div>
    );
}

export default HappensNext;
