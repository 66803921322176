import React, { useEffect } from "react";
import Nav from "../../components/Nav/Nav";
import Screen_Resize from "../../config/ScreenResize";
import Helpyou from "../../components/Helpyou/Helpyou";
import Pronetwork from "../../components/Pronetwork/Pronetwork";
import Pagefooter from "../../components/Pagefooter/Pagefooter";
import Homegallary from "../../components/Homegallary/Homegallary";
import Howwehelp from "../../components/Howwehelp/Howwehelp";
import PageHeroSec from "../../components/PageHeroSec/PageHeroSec";
import AboutSecContent from "../../components/AboutSecContent/AboutSecContent";
import WhyUs from "../../components/WhyUs/WhyUs";
import PriceEstimate from "../../components/PriceEstimate/PriceEstimate";
import { images } from "../../config/images";

const AboutUs = () => {
    useEffect(() => {
        Screen_Resize();
    }, []);

    return (
        <>
            <Nav />
            <div className="MainBody">
                <PageHeroSec
                    label="About us"
                    title="We help people find the best local contractors"
                    line1="Home improvements are the best way to improve
                                the functionality and enjoyment of your home as
                                well as improve the value of your property!"
                    line2=" We help you to find affordable home improvement
                                quotes for your home to not only add windows and
                                fix your roof or gutters but also to improve
                                your kitchens and bathrooms or replace plumbing
                                (and anything else you need remodelling)."
                    ImgUrl={images.AboutUsHero}
                    ImgAlt="Helpful Homes"
                />
                <Howwehelp url="/windows/form" />
                <AboutSecContent />
                <Homegallary />
                <WhyUs />
                <PriceEstimate />
                <Helpyou />
                <Pronetwork />
                <Pagefooter />
            </div>
        </>
    );
};

export default AboutUs;
