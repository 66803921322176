import React from "react";

function Servicecard(props) {
    return (
        <div className="serviceCard">
            <div className="cardtext">
                <h3 className="h3">{props.cardHeader}</h3>
                <p className="mdtext">{props.cardSub}</p>
            </div>
            <div className="servicecardImg">
                <img src={props.images} alt={props.cardHeader} />
            </div>
        </div>
    );
}

export default Servicecard;
