import React from "react";
import { Fade } from "react-awesome-reveal";
import { images } from "../../config/images";

function Homegallary() {
    return (
        <div className="HomeGallary">
            <div className="h-container">
                <Fade>
                    <div className="gallaryContainer">
                        <div className="centerCircle"></div>
                        <div className="gallaryLeft">
                            <div className="gallary-imgtop">
                                <div className="overlayImg">
                                    <img
                                        src={images.Imgborder2}
                                        alt="Helpful Homes"
                                    />
                                </div>
                                <img src={images.g1} alt="Helpful Homes" />
                            </div>
                            <div className="gallary-imgbottom">
                                <img src={images.g2} alt="Helpful Homes" />
                            </div>
                        </div>
                        <div className="gallaryCenter">
                            <div className="gallary-center">
                                <div className="overlayImg">
                                    <img
                                        src={images.Imgborder1}
                                        alt="Helpful Homes"
                                    />
                                </div>
                                <img src={images.GCenter} alt="Helpful Homes" />
                            </div>
                            {/* <House1Shape></House1Shape> */}
                        </div>
                        <div className="gallaryRight">
                            <div className="gallary-imgtop">
                                <div className="overlayImg">
                                    <img
                                        src={images.Imgborder2}
                                        alt="Helpful Homes"
                                    />
                                </div>
                                <img src={images.g3} alt="Helpful Homes" />
                            </div>
                            <div className="gallary-imgbottom">
                                <img src={images.g4} alt="Helpful Homes" />
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
        </div>
    );
}

export default Homegallary;
