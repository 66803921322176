import React from "react";

function LocationMarker() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M23.5433 22.2094L17.886 27.8668C17.6386 28.1144 17.3448 28.3109 17.0214 28.4449C16.698 28.579 16.3514 28.648 16.0013 28.648C15.6513 28.648 15.3046 28.579 14.9812 28.4449C14.6579 28.3109 14.3641 28.1144 14.1167 27.8668L8.45799 22.2094C6.96628 20.7176 5.95044 18.817 5.53891 16.7479C5.12737 14.6788 5.33864 12.5341 6.14599 10.5851C6.95334 8.63602 8.32052 6.97015 10.0746 5.79811C11.8287 4.62606 13.891 4.00049 16.0007 4.00049C18.1103 4.00049 20.1726 4.62606 21.9267 5.79811C23.6808 6.97015 25.048 8.63602 25.8553 10.5851C26.6627 12.5341 26.8739 14.6788 26.4624 16.7479C26.0509 18.817 25.035 20.7176 23.5433 22.2094V22.2094Z"
                stroke="#03093D"
                strokeWidth="2.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.8284 17.4952C19.5786 16.745 20 15.7276 20 14.6667C20 13.6059 19.5786 12.5885 18.8284 11.8383C18.0783 11.0882 17.0609 10.6667 16 10.6667C14.9391 10.6667 13.9217 11.0882 13.1716 11.8383C12.4214 12.5885 12 13.6059 12 14.6667C12 15.7276 12.4214 16.745 13.1716 17.4952C13.9217 18.2453 14.9391 18.6667 16 18.6667C17.0609 18.6667 18.0783 18.2453 18.8284 17.4952Z"
                stroke="#3F3F46"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default LocationMarker;
