import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { images } from "../../config/images";
import { customAnimation } from "../../config/animation";
import {
    SliderArrowRight,
    SliderArrowLeft,
} from "../../components/Icons/index";
import Reveal, { Fade } from "react-awesome-reveal";
import BeforeAfterPopup from "../BeforeAfterPopup/BeforeAfterPopup";
import { BASlide } from "./BASlide";

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <SliderArrowRight />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <SliderArrowLeft />
        </div>
    );
}

function Beforeafterslider(props) {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        draggable: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        console.log("function calling");
        setIsOpen(true);
    }
    function closeModal() {
        console.log("function calling");
        setIsOpen(false);
    }

    return (
        <div className={`BeforeaftersliderStyles ${props.className}`}>
            <div className="h-container">
                <Reveal duration={800} keyframes={customAnimation}>
                    <div className="PageTile">
                        <h2 className="h2 text-center lg:text-left">
                            Before/After photos
                        </h2>
                    </div>
                </Reveal>
                <div className="testimonial-wrapper">
                    <div className="testimonial-slider">
                        <Fade>
                            <Slider {...settings}>
                                <BASlide
                                    onClick={openModal}
                                    beforeImg={images.beforeimg}
                                    beforeImgName="Helpful Homes"
                                    afterImg={images.afterimg}
                                    afterImgName="Helpful Homes"
                                />

                                <BASlide
                                    onClick={openModal}
                                    beforeImg={images.beforeimg}
                                    beforeImgName="Helpful Homes"
                                    afterImg={images.afterimg}
                                    afterImgName="Helpful Homes"
                                />

                                <BASlide
                                    onClick={openModal}
                                    beforeImg={images.beforeimg}
                                    beforeImgName="Helpful Homes"
                                    afterImg={images.afterimg}
                                    afterImgName="Helpful Homes"
                                />
                            </Slider>
                        </Fade>
                    </div>
                </div>
            </div>

            {/* <button onClick={openModal}> test </button> */}

            {modalIsOpen && (
                <BeforeAfterPopup
                    closeModal={closeModal}
                    modalIsOpen={modalIsOpen}
                />
            )}
        </div>
    );
}

export default Beforeafterslider;
