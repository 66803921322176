import React from "react";

function BackArwDark() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M3 12H21M7 16L3 12L7 16ZM3 12L7 8L3 12Z"
                stroke="#ffffff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default BackArwDark;
