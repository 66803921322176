import React from "react";

function HomeGreen() {
    return (
        <svg width="27" height="28" viewBox="0 0 27 28" fill="none">
            <path
                d="M1.49902 14.0002L4.16569 11.3336M4.16569 11.3336L13.499 2.00024L22.8324 11.3336M4.16569 11.3336V24.6669C4.16569 25.0205 4.30617 25.3597 4.55621 25.6097C4.80626 25.8598 5.1454 26.0002 5.49902 26.0002H9.49902M22.8324 11.3336L25.499 14.0002M22.8324 11.3336V24.6669C22.8324 25.0205 22.6919 25.3597 22.4418 25.6097C22.1918 25.8598 21.8526 26.0002 21.499 26.0002H17.499M9.49902 26.0002C9.85265 26.0002 10.1918 25.8598 10.4418 25.6097C10.6919 25.3597 10.8324 25.0205 10.8324 24.6669V19.3336C10.8324 18.98 10.9728 18.6408 11.2229 18.3908C11.4729 18.1407 11.8121 18.0002 12.1657 18.0002H14.8324C15.186 18.0002 15.5251 18.1407 15.7752 18.3908C16.0252 18.6408 16.1657 18.98 16.1657 19.3336V24.6669C16.1657 25.0205 16.3062 25.3597 16.5562 25.6097C16.8063 25.8598 17.1454 26.0002 17.499 26.0002M9.49902 26.0002H17.499"
                stroke="#1CCA96"
                strokeWidth="2.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default HomeGreen;
