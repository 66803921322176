import React from "react";

function Users() {
    return (
        <svg width="31" height="24" viewBox="0 0 31 24" fill="none">
            <path
                d="M22.166 22.6666H28.8327V20C28.8326 19.1687 28.5736 18.3581 28.0915 17.6808C27.6095 17.0036 26.9284 16.4933 26.143 16.221C25.3576 15.9487 24.5068 15.9278 23.709 16.1613C22.9112 16.3949 22.206 16.8711 21.6913 17.524M22.166 22.6666H8.83268M22.166 22.6666V20C22.166 19.1253 21.998 18.2893 21.6913 17.524M21.6913 17.524C21.1962 16.2866 20.3417 15.2259 19.238 14.4788C18.1343 13.7317 16.8321 13.3324 15.4993 13.3324C14.1666 13.3324 12.8644 13.7317 11.7607 14.4788C10.657 15.2259 9.80249 16.2866 9.30735 17.524M8.83268 22.6666H2.16602V20C2.16608 19.1687 2.42513 18.3581 2.90716 17.6808C3.3892 17.0036 4.07026 16.4933 4.85568 16.221C5.6411 15.9487 6.49185 15.9278 7.28967 16.1613C8.08748 16.3949 8.79272 16.8711 9.30735 17.524M8.83268 22.6666V20C8.83268 19.1253 9.00068 18.2893 9.30735 17.524M19.4993 5.33331C19.4993 6.39418 19.0779 7.41159 18.3278 8.16174C17.5776 8.91189 16.5602 9.33331 15.4993 9.33331C14.4385 9.33331 13.4211 8.91189 12.6709 8.16174C11.9208 7.41159 11.4993 6.39418 11.4993 5.33331C11.4993 4.27245 11.9208 3.25503 12.6709 2.50489C13.4211 1.75474 14.4385 1.33331 15.4993 1.33331C16.5602 1.33331 17.5776 1.75474 18.3278 2.50489C19.0779 3.25503 19.4993 4.27245 19.4993 5.33331ZM27.4993 9.33331C27.4993 10.0406 27.2184 10.7188 26.7183 11.2189C26.2182 11.719 25.5399 12 24.8327 12C24.1254 12 23.4472 11.719 22.9471 11.2189C22.447 10.7188 22.166 10.0406 22.166 9.33331C22.166 8.62607 22.447 7.94779 22.9471 7.4477C23.4472 6.9476 24.1254 6.66665 24.8327 6.66665C25.5399 6.66665 26.2182 6.9476 26.7183 7.4477C27.2184 7.94779 27.4993 8.62607 27.4993 9.33331ZM8.83268 9.33331C8.83268 10.0406 8.55173 10.7188 8.05163 11.2189C7.55154 11.719 6.87326 12 6.16602 12C5.45877 12 4.78049 11.719 4.2804 11.2189C3.7803 10.7188 3.49935 10.0406 3.49935 9.33331C3.49935 8.62607 3.7803 7.94779 4.2804 7.4477C4.78049 6.9476 5.45877 6.66665 6.16602 6.66665C6.87326 6.66665 7.55154 6.9476 8.05163 7.4477C8.55173 7.94779 8.83268 8.62607 8.83268 9.33331Z"
                stroke="#03093D"
                strokeWidth="2.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Users;
