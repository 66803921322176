import React from "react";
import { customAnimation } from "../../config/animation";
import Reveal from "react-awesome-reveal";
import { images } from "../../config/images";
import { Mail } from "../../components/Icons/index";
import { FooterLinkItems } from "./LinkItems";

function Pagefooter() {
    return (
        <div className="pageFooter">
            <div className="whitebg"></div>
            <div className="h-container">
                <Reveal duration={800} keyframes={customAnimation}>
                    <div className="grid grid-cols-1  lg:grid-cols-2 lg:gap-6 gap-4">
                        <div className="footerLeft">
                            <h5 className="text-center lg:text-left">
                                Deals, Inspiration and Trends
                            </h5>
                            <p>Subscribe to our news</p>
                            <form className="footerform">
                                <input
                                    className="primary-input placeholder-shown:border-gray-500 ..."
                                    placeholder="Email"
                                />
                                <input
                                    className="primary-input placeholder-shown:border-gray-500 ..."
                                    placeholder="ZIP code"
                                />
                                <button className="primary-btn full filled">
                                    <Mail /> SUBSCRIBE
                                </button>
                            </form>
                        </div>
                        <div className="footerRight">
                            <a href="/" className="footerLogo">
                                <img
                                    src={images.Logomobile}
                                    alt="Helpful Homes"
                                />
                            </a>
                            <ul className="footerlinks">
                                <FooterLinkItems
                                    link="/aboutus"
                                    text="About us"
                                />
                                <FooterLinkItems
                                    link="/becomeahomepro"
                                    text="Become a Home Pro"
                                />
                                <FooterLinkItems link="" text="Contact Us" />
                                <FooterLinkItems link="" text="Privacy" />
                                <FooterLinkItems link="" text="Terms" />
                                <FooterLinkItems
                                    link=""
                                    text="Do Not Sell My Personal Information"
                                />
                            </ul>
                            <div className="footertext">
                                <p>
                                    This website is an advertising marketplace
                                    for companies seeking consumer referrals for
                                    home improvement. We are not home
                                    improvement contractors. The information
                                    provided by you to us will be securely
                                    delivered to a licensed company. We do not
                                    endorse, warrant, or guarantee any service
                                    or products of any company. The content
                                    of any information or service contained
                                    on this website is provided
                                    on an as is basis. Minimum age required
                                    to be eligible is 18.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="copyright">
                        <p>
                            This site is protected by reCAPTCHA and the Google
                            Privacy Policy and Terms of Service apply.
                        </p>
                        <div className="copy-right">
                            <span>© Copyright 2022</span>
                            <span>All rights reserved</span>
                        </div>
                    </div>
                </Reveal>
            </div>
        </div>
    );
}

export default Pagefooter;
