import React, { useState } from "react";
import ToggleUpArw from "../Icons/ToggleUpArw";
import ToggleDownArw from "../Icons/ToggleDownArw";

export function QAItem(props) {
    let index = 0;

    const [clicked, setClicked] = useState(false);

    const toggle = (index) => {
        if (clicked === index) {
            //if clicked question is already active, then close it
            return setClicked(null);
        }

        setClicked(index);
    };

    return (
        <>
            <div className="ItemWrap">
                <div
                    className="AccHeader"
                    onClick={() => toggle(index)}
                    key={index}
                >
                    <h4>
                        {props.question}
                        {clicked === index ? (
                            <ToggleUpArw />
                        ) : (
                            <ToggleDownArw />
                        )}
                    </h4>
                </div>
                {clicked === index ? (
                    <div className="AccContent">
                        <p>{props.answer}</p>
                    </div>
                ) : null}
            </div>
        </>
    );
}
