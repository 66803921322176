import React from "react";

function USDGreen() {
    return (
        <svg width="27" height="28" viewBox="0 0 27 28" fill="none">
            <path
                d="M13.5 8.66667C11.2907 8.66667 9.5 9.86 9.5 11.3333C9.5 12.8067 11.2907 14 13.5 14C15.7093 14 17.5 15.1933 17.5 16.6667C17.5 18.14 15.7093 19.3333 13.5 19.3333M13.5 8.66667V19.3333M13.5 8.66667C14.98 8.66667 16.2733 9.20267 16.9653 10M13.5 8.66667V7.33333M13.5 19.3333V20.6667M13.5 19.3333C12.02 19.3333 10.7267 18.7973 10.0347 18M25.5 14C25.5 15.5759 25.1896 17.1363 24.5866 18.5922C23.9835 20.0481 23.0996 21.371 21.9853 22.4853C20.871 23.5996 19.5481 24.4835 18.0922 25.0866C16.6363 25.6896 15.0759 26 13.5 26C11.9241 26 10.3637 25.6896 8.9078 25.0866C7.45189 24.4835 6.12902 23.5996 5.01472 22.4853C3.90042 21.371 3.0165 20.0481 2.41345 18.5922C1.81039 17.1363 1.5 15.5759 1.5 14C1.5 10.8174 2.76428 7.76516 5.01472 5.51472C7.26516 3.26428 10.3174 2 13.5 2C16.6826 2 19.7348 3.26428 21.9853 5.51472C24.2357 7.76516 25.5 10.8174 25.5 14Z"
                stroke="#1CCA96"
                strokeWidth="2.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default USDGreen;
