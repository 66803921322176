import React from "react";

export function StepsItem(props) {
    return (
        <>
            <div className="PriceItem">
                <h3>{props.heading}</h3>
                <h4>{props.title}</h4>
                <p>{props.description}</p>
            </div>
        </>
    );
}
