import React from "react";

const SolarFormStep1 = ({ onNextClick }) => {
    return (
        <div className="form">
            <div className="form-item">
                <div className="OpInput controlRadio RadioFull">
                    <input
                        type="radio"
                        name="Q1Solar"
                        id="Q1Solar10plus"
                        onClick={onNextClick}
                    />
                    <label htmlFor="Q1Solar10plus">10+</label>
                </div>
                <div className="OpInput controlRadio RadioFull">
                    <input
                        type="radio"
                        name="Q1Solar"
                        id="Q1Solar6to9"
                        onClick={onNextClick}
                    />
                    <label htmlFor="Q1Solar6to9">6-9</label>
                </div>
                <div className="OpInput controlRadio RadioFull">
                    <input
                        type="radio"
                        name="Q1Solar"
                        id="Q1Solar3to5"
                        onClick={onNextClick}
                    />
                    <label htmlFor="Q1Solar3to5">3-5</label>
                </div>
                <div className="OpInput controlRadio RadioFull">
                    <input
                        type="radio"
                        name="Q1Solar"
                        id="Q1Solar2"
                        onClick={onNextClick}
                    />
                    <label htmlFor="Q1Solar2">2</label>
                </div>
                <div className="OpInput controlRadio RadioFull">
                    <input
                        type="radio"
                        name="Q1Solar"
                        id="Q1Solar1"
                        onClick={onNextClick}
                    />
                    <label htmlFor="Q1Solar1">1</label>
                </div>
            </div>
        </div>
    );
};
const SolarFormStep2 = ({ onNextClick }) => {
    return (
        <div className="form">
            <div className="form-item">
                <div className="OpInput controlRadio RadioFull">
                    <input
                        type="radio"
                        name="Q2Help"
                        id="Q2Install"
                        onClick={onNextClick}
                    />
                    <label htmlFor="Q2Install">Install Windows</label>
                </div>
                <div className="OpInput controlRadio RadioFull">
                    <input
                        type="radio"
                        name="Q2Help"
                        id="Q2Replace"
                        onClick={onNextClick}
                    />
                    <label htmlFor="Q2Replace">Replace Windows</label>
                </div>
                <div className="OpInput controlRadio RadioFull">
                    <input
                        type="radio"
                        name="Q2Help"
                        id="Q2Repair"
                        onClick={onNextClick}
                    />
                    <label htmlFor="Q2Repair">Repair Windows</label>
                </div>
            </div>
        </div>
    );
};

const SolarFormStep3 = ({ onNextClick }) => {
    return (
        <div className="form">
            <div className="form-item">
                <div className="OpInput controlRadio RadioFull">
                    <input
                        type="radio"
                        name="Q3OwnHome"
                        id="Q3Yes"
                        onClick={onNextClick}
                    />
                    <label htmlFor="Q3Yes">Yes</label>
                </div>
                <div className="OpInput controlRadio RadioFull">
                    <input
                        type="radio"
                        name="Q3OwnHome"
                        id="Q3No"
                        onClick={onNextClick}
                    />
                    <label htmlFor="Q3No">No</label>
                </div>
            </div>
        </div>
    );
};

const SolarFormStep4 = ({ onNextClick }) => {
    return (
        <div className="form">
            <div className="form-item">
                <div className="OpInput controlRadio RadioFull">
                    <input
                        type="radio"
                        name="Q4Start"
                        id="Q4Immediately"
                        onClick={onNextClick}
                    />
                    <label htmlFor="Q4Immediately">Immediately</label>
                </div>
                <div className="OpInput controlRadio RadioFull">
                    <input
                        type="radio"
                        name="Q4Start"
                        id="Q4Within6"
                        onClick={onNextClick}
                    />
                    <label htmlFor="Q4Within6">Within 6 Months</label>
                </div>
                <div className="OpInput controlRadio RadioFull">
                    <input
                        type="radio"
                        name="Q4Start"
                        id="Q4Not"
                        onClick={onNextClick}
                    />
                    <label htmlFor="Q4Not">Not sure</label>
                </div>
            </div>
        </div>
    );
};

const SolarFormStep5 = () => {
    return (
        <div className="form">
            <div className="form-item">
                <p className="subTitle">Get a FREE quote now - No Obligation</p>
            </div>

            <div className="form-item">
                <input
                    type="text"
                    className="form-input"
                    name="FirstName"
                    placeholder="First Name"
                />
            </div>
            <div className="form-item">
                <input
                    type="text"
                    className="form-input"
                    name="LastName"
                    placeholder="Last Name"
                />
            </div>
        </div>
    );
};

const SolarFormStep6 = () => {
    return (
        <div className="form">
            <div className="form-item">
                <p className="subTitle">How can we contact you?</p>
            </div>

            <div className="form-item">
                <input
                    type="phone"
                    className="form-input"
                    name="phone"
                    placeholder="Phone"
                />
            </div>
            <div className="form-item">
                <input
                    type="email"
                    className="form-input"
                    name="email"
                    placeholder="Email"
                />
            </div>
        </div>
    );
};

const SolarFormStep7 = () => {
    return (
        <div className="form">
            <div className="form-item">
                <input
                    type="text"
                    className="form-input"
                    name="StreetAddress"
                    placeholder="Street Address"
                />
            </div>
            <div className="form-item">
                <input
                    type="text"
                    className="form-input"
                    name="ZipCode"
                    placeholder="Zip Code"
                />
                <div className="msg-info">
                    When you click Continue, you authorize companies on this
                    list to call and send pre-recorded and sms messages to phone
                    number above using an autodialer even if your phone number
                    is on any do not call list. Your consent here is not based
                    on a condition of purchase.
                </div>
            </div>
        </div>
    );
};

export {
    SolarFormStep1,
    SolarFormStep2,
    SolarFormStep3,
    SolarFormStep4,
    SolarFormStep5,
    SolarFormStep6,
    SolarFormStep7,
};
