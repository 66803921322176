import React from "react";

function RightBtnArrow() {
  return (
    <svg width="21" height="10" viewBox="0 0 21 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 1L19.5 5M19.5 5L15.5 9M19.5 5H1.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    

  );
}

export default RightBtnArrow;