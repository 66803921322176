import React, { useState } from "react";
import { images } from "../../config/images";
import { customAnimation } from "../../config/animation";
import Reveal from "react-awesome-reveal";
import { Check } from "../Icons/index";
import { NavLink } from "react-router-dom";
import JoinNetwork from "../JoinNetwork/JoinNetwork";

function Pronetwork() {
    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div className="Pronetwork">
            <div className="h-container">
                <Reveal duration={800} keyframes={customAnimation}>
                    <div className="flex flex-col lg:grid grid-cols-1 lg:grid-cols-2 lg:gap-6 gap-4">
                        <div className="imageLeft order-2 lg:order-1 mx-auto">
                            <div className="blureCircle"></div>
                            <img
                                src={images.proNetwork}
                                className="hidden md:block"
                                alt="Helpful Homes"
                            />
                            <img
                                src={images.MPhotos}
                                className="block md:hidden"
                                alt="Helpful Homes"
                            />
                        </div>
                        <div className="networkRight order-1 mx-auto lg:order-2">
                            <h2 className="h2 text-center lg:text-left">
                                Join Our Home Pro Network
                            </h2>
                            <p>
                                Our expansive contractor network works with you
                                to give personalized pricing quotes.
                            </p>
                            {/* <p>Customers trust Helpful Home Pros to deliver an exceptional experience with superior professionalism.</p> */}
                            <div className="Networkbuttons">
                                <NavLink
                                    to="/becomeahomepro"
                                    className="primary-btn big mr-4"
                                >
                                    READ MORE
                                </NavLink>
                                <button
                                    className=" primary-btn  big  filled"
                                    type="button"
                                    onClick={openModal}
                                >
                                    <Check /> APPLY NOW
                                </button>
                            </div>
                        </div>
                    </div>
                </Reveal>
            </div>
            {modalIsOpen && (
                <JoinNetwork
                    closeModal={closeModal}
                    modalIsOpen={modalIsOpen}
                />
            )}
        </div>
    );
}

export default Pronetwork;
