import React from "react";
import { customAnimation } from "../../config/animation";
import Reveal, { Fade } from "react-awesome-reveal";
import { images } from "../../config/images";
import Helpcard from "../../components/Helpcard/Helpcard";

function Helpyou(props) {
    return (
        <div className="Helpyou">
            <Fade direction="top">
                <div className="shapeRight">
                    <img src={images.homebg} alt="Helpful Homes" />
                </div>
            </Fade>
            <div className="h-container">
                <Reveal duration={800} keyframes={customAnimation}>
                    <div className="sectiontitle">
                        <h2 className="h2 text-center lg:text-left">
                            Home Improvement Pros Waiting to Help You!
                        </h2>
                        <p className="text-h6 leading-7 text-center lg:text-left">
                            What can we help you with?
                        </p>
                    </div>
                </Reveal>
                <div className="CardBody grid grid-cols-2  lg:grid-cols-4 lg:gap-6 gap-4">
                    <Reveal duration={800} keyframes={customAnimation}>
                        <Helpcard
                            cardName={"Windows"}
                            link="/windows"
                            images={images.pros1}
                        />
                        <Helpcard
                            cardName={"Roofing"}
                            link="/roofing"
                            images={images.pros2}
                        />
                        <Helpcard
                            cardName={"Bathrooms"}
                            link="/bathroom"
                            images={images.pros3}
                        />
                        <Helpcard
                            cardName={"Solar"}
                            link="/solar"
                            images={images.pros4}
                        />
                    </Reveal>
                </div>
            </div>
        </div>
    );
}

export default Helpyou;
