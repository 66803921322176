import React from "react";
import { customAnimation } from "../../config/animation";
import Reveal from "react-awesome-reveal";
import { RightArrow } from "../Icons";
import { useHistory } from "react-router-dom";

function CatQuote(props) {
    const history = useHistory();

    return (
        <div className="CatQuote">
            <div className="h-container">
                <div className="CatBlocks">
                    <Reveal duration={800} keyframes={customAnimation}>
                        <div className="CardBody">
                            <div className="PageTile">
                                <h2>{props.title}</h2>
                                <p>{props.description}</p>
                                <button
                                    className="primary-btn full big filled"
                                    type="button"
                                    onClick={() => {
                                        history.push(props.ButtonUrl);
                                    }}
                                >
                                    <RightArrow /> GET FREE QUOTE
                                </button>
                            </div>

                            <div className="ContentImg">
                                <div className="WrapImg">
                                    <img
                                        src={props.ImgUrl}
                                        alt={props.ImgAlt}
                                    />
                                </div>
                            </div>
                        </div>
                    </Reveal>
                </div>
            </div>
        </div>
    );
}

export default CatQuote;
