import React from "react";
import { customAnimation } from "../../config/animation";
import Reveal from "react-awesome-reveal";
import { BlockItem } from "../common/BlockItem";
import GrowGreen from "../Icons/GrowGreen";
import HomeGreen from "../Icons/HomeGreen";
import USDGreen from "../Icons/USDGreen";

function CatBenifits({ page }) {
    const getBenifits = () => {
        switch (page) {
            case "window":
                return (
                    <div className="CardBody">
                        <BlockItem
                            svg={
                                <>
                                    <USDGreen />
                                </>
                            }
                            title="Installing New Windows In Your Home Is a Great Investment!"
                            description="Save on electric bills and get fantastic tax rebates!"
                        />
                        <BlockItem
                            svg={
                                <>
                                    <HomeGreen />
                                </>
                            }
                            title="Come back to a Safer, Warmer, Comfortable Home."
                            description="Improve the comfort of your home by removing drafty, outdated windows"
                        />
                        <BlockItem
                            svg={
                                <>
                                    <GrowGreen />
                                </>
                            }
                            title="Add Value and Appeal to Your Investment!"
                            description="Reduce noise and enhance your home’s curb appeal and home value."
                        />
                    </div>
                );
            case "roofing":
                return (
                    <div className="CardBody">
                        <BlockItem
                            svg={
                                <>
                                    <USDGreen />
                                </>
                            }
                            title="To Protect Your Investment"
                            description="Storms, high wind, tree branches and even time affect any type of roof. Depending on the damage, you risk far more costly repairs by ignoring the roof of your biggest investment - your home!"
                        />
                        <BlockItem
                            svg={
                                <>
                                    <HomeGreen />
                                </>
                            }
                            title="Repairs Reduce Energy Bills and Qualify for Rebates"
                            description="The majority of heat loss in a home comes from the roof! By replacing or repairing your roof you’ll decrease electricity bills and potentially save thousands. Plus, there are various tax rebates on home improvements!"
                        />
                        <BlockItem
                            svg={
                                <>
                                    <GrowGreen />
                                </>
                            }
                            title="Boost Your Home’s Value When You Remodel or Sell"
                            description="Most homeowners doing home renovations or additions replace the entire roof to make their roof look as new as possible when planning to "
                        />
                    </div>
                );

            case "bathroom":
                return (
                    <div className="CardBody">
                        <BlockItem
                            svg={
                                <>
                                    <USDGreen />
                                </>
                            }
                            title="Walk-In Bathtubs are Luxurious and Enjoyable Investment"
                            description="Get a spa-like experience right at home without spending a ton of money! Walk-in bathtubs give you all the functionality of a bathtub with a ton of extra features like whirlpools and jets."
                        />
                        <BlockItem
                            svg={
                                <>
                                    <HomeGreen />
                                </>
                            }
                            title="Make Your Home Safer For You in the Future"
                            description="Walk-in tubs combine low step-in entry with handrails and wide doors to make getting in and out of the tub easier than ever before. Textured patterns add footing and guarantee safety and convenience over traditional bathtubs or showers."
                        />
                        <BlockItem
                            svg={
                                <>
                                    <GrowGreen />
                                </>
                            }
                            title="Add Value and Appeal to Your Home"
                            description="Walk in tubs are versatile and easy to use! They’re a smart investment for both resale value and accessibility, especially if there’s limited space in your bathroom."
                        />
                    </div>
                );

            case "solar":
                return (
                    <div className="CardBody">
                        <BlockItem
                            svg={
                                <>
                                    <USDGreen />
                                </>
                            }
                            title="Remodeling Your Home Is a Great Investment"
                            description="Updates to your home improve your home value! Even small upgrades can add tens of thousands of dollars to the value of your home and update your home’s dated look."
                        />
                        <BlockItem
                            svg={
                                <>
                                    <HomeGreen />
                                </>
                            }
                            title="Enjoy Your Home"
                            description="Enjoy new bedroom, living room or kitchen with your family. Remodeling makes your home even better place to be. "
                        />
                        <BlockItem
                            svg={
                                <>
                                    <GrowGreen />
                                </>
                            }
                            title="Fix Safety Issues or Add Energy Efficiency, Saving You Money"
                            description="Most homes are old and outdated. Simply adding insulation, new ducting or fixing small defects can pay for themselves over time in energy savings. More efficient homes are worth more to home buyers in the future."
                        />
                    </div>
                );

            default:
                break;
        }
    };

    return (
        <div className="CatBenifits">
            <div className="h-container">
                <Reveal duration={800} keyframes={customAnimation}>
                    <div className="secHeading">
                        <h2>Benefits for you</h2>
                    </div>
                </Reveal>

                <Reveal duration={800} keyframes={customAnimation}>
                    {getBenifits()}
                </Reveal>
            </div>
        </div>
    );
}

export default CatBenifits;
