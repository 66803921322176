import React from "react";

function Check() {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none">
            <path
                d="M15.0938 17H3.09375C2.56332 17 2.05461 16.7893 1.67954 16.4142C1.30446 16.0391 1.09375 15.5304 1.09375 15V3C1.09375 2.46957 1.30446 1.96086 1.67954 1.58579C2.05461 1.21071 2.56332 1 3.09375 1H15.0938C15.6242 1 16.1329 1.21071 16.508 1.58579C16.883 1.96086 17.0938 2.46957 17.0938 3V15C17.0938 15.5304 16.883 16.0391 16.508 16.4142C16.1329 16.7893 15.6242 17 15.0938 17Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.09375 9.6L7.66518 12L13.0938 7"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Check;
