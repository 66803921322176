import React from "react";

function CloseWhite() {
    return (
        <svg viewBox="0 0 14 14" fill="none">
            <path
                d="M1 13L13 1M1 1L13 13"
                stroke="#ffffff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default CloseWhite;
