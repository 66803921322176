import React from "react";

function StatusCheck() {
    return (
        <svg width="46" height="34" viewBox="0 0 46 34" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44.5345 1.89122C45.1363 2.49317 45.4743 3.30946 45.4743 4.16061C45.4743 5.01175 45.1363 5.82805 44.5345 6.42999L18.8555 32.109C18.2536 32.7108 17.4373 33.0488 16.5861 33.0488C15.735 33.0488 14.9187 32.7108 14.3167 32.109L1.47722 19.2695C0.892517 18.6641 0.56898 17.8533 0.576293 17.0117C0.583607 16.17 0.921186 15.365 1.51632 14.7698C2.11146 14.1747 2.91654 13.8371 3.75816 13.8298C4.59978 13.8225 5.4106 14.146 6.01599 14.7307L16.5861 25.3009L39.9957 1.89122C40.5977 1.28947 41.414 0.951416 42.2651 0.951416C43.1163 0.951416 43.9326 1.28947 44.5345 1.89122Z"
                fill="#1CCA96"
            />
        </svg>
    );
}

export default StatusCheck;
