import React from "react";

function SliderArrowLeft() {
  return (
   
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.4406 -9.80909e-07L9.82408 -4.29424e-07C9.3638 -4.09305e-07 8.92702 0.209243 8.63072 0.571697L0.366064 10.6817C-0.129728 11.2881 -0.12097 12.1763 0.38668 12.7723L8.63218 22.453C8.92828 22.8006 9.35569 23 9.80492 23L22.4406 23C23.3018 23 24 22.2816 24 21.3953L24 1.60465C24 0.718426 23.3018 -1.01856e-06 22.4406 -9.80909e-07Z" fill="#1CCA96"/>
    </svg>

    
    

  );
}

export default SliderArrowLeft;