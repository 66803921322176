import React from "react";

function Sparkles() {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path
                d="M4.66667 2V7.33333V2ZM2 4.66667H7.33333H2ZM6 20.6667V26V20.6667ZM3.33333 23.3333H8.66667H3.33333ZM15.3333 2L18.3813 11.1427L26 14L18.3813 16.8573L15.3333 26L12.2853 16.8573L4.66667 14L12.2853 11.1427L15.3333 2Z"
                stroke="#03093D"
                strokeWidth="2.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Sparkles;
