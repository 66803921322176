import React from "react";

function Earth() {
    return (
        <svg width="27" height="28" viewBox="0 0 27 28" fill="none">
            <path
                d="M1.57333 12.6667H4.16667C4.87391 12.6667 5.55219 12.9476 6.05229 13.4477C6.55238 13.9478 6.83333 14.6261 6.83333 15.3333V16.6667C6.83333 17.3739 7.11428 18.0522 7.61438 18.5523C8.11448 19.0524 8.79276 19.3333 9.5 19.3333C10.2072 19.3333 10.8855 19.6143 11.3856 20.1144C11.8857 20.6145 12.1667 21.2928 12.1667 22V25.9267M8.16667 3.24667V5.33333C8.16667 6.21739 8.51786 7.06523 9.14298 7.69036C9.7681 8.31548 10.6159 8.66667 11.5 8.66667H12.1667C12.8739 8.66667 13.5522 8.94762 14.0523 9.44772C14.5524 9.94781 14.8333 10.6261 14.8333 11.3333C14.8333 12.0406 15.1143 12.7189 15.6144 13.219C16.1145 13.719 16.7928 14 17.5 14C18.2072 14 18.8855 13.719 19.3856 13.219C19.8857 12.7189 20.1667 12.0406 20.1667 11.3333C20.1667 10.6261 20.4476 9.94781 20.9477 9.44772C21.4478 8.94762 22.1261 8.66667 22.8333 8.66667H24.252M17.5 25.3173V22C17.5 21.2928 17.781 20.6145 18.281 20.1144C18.7811 19.6143 19.4594 19.3333 20.1667 19.3333H24.252M25.5 14C25.5 15.5759 25.1896 17.1363 24.5866 18.5922C23.9835 20.0481 23.0996 21.371 21.9853 22.4853C20.871 23.5996 19.5481 24.4835 18.0922 25.0866C16.6363 25.6896 15.0759 26 13.5 26C11.9241 26 10.3637 25.6896 8.9078 25.0866C7.45189 24.4835 6.12902 23.5996 5.01472 22.4853C3.90042 21.371 3.0165 20.0481 2.41345 18.5922C1.81039 17.1363 1.5 15.5759 1.5 14C1.5 10.8174 2.76428 7.76516 5.01472 5.51472C7.26516 3.26428 10.3174 2 13.5 2C16.6826 2 19.7348 3.26428 21.9853 5.51472C24.2357 7.76516 25.5 10.8174 25.5 14Z"
                stroke="#03093D"
                strokeWidth="2.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Earth;
