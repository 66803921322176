export const images = {
    logoMain: require("./../assets/images/Logotype.png"),
    Logomobile: require("./../assets/images/LogoMobile.png"),
    Service1: require("./../assets/images/service1.png"),
    Service2: require("./../assets/images/Service2.png"),
    Service3: require("./../assets/images/Service3.png"),
    Service4: require("./../assets/images/Service4.png"),

    Illustration1: require("./../assets/images/Illustration1.png"),
    Illustration2: require("./../assets/images/Illustration2.png"),
    Illustration3: require("./../assets/images/Illustration3.png"),
    Illustration4: require("./../assets/images/Illustration4.png"),

    proNetwork: require("./../assets/images/proNetwork.png"),

    pros1: require("./../assets/images/pros1.png"),
    pros2: require("./../assets/images/pros2.png"),
    pros3: require("./../assets/images/pros3.png"),
    pros4: require("./../assets/images/pros4.png"),

    homebg: require("./../assets/images/homebg.png"),
    MPhotos: require("./../assets/images/MPhotos.png"),
    User: require("./../assets/images/user.png"),

    Imgborder1: require("./../assets/images/Imgborder1.png"),
    Imgborder2: require("./../assets/images/Imgborder2.png"),

    beforeimg: require("./../assets/images/beforeimg.png"),
    afterimg: require("./../assets/images/afterimg.png"),

    g1: require("./../assets/images/g1.png"),
    g2: require("./../assets/images/g2.png"),
    g3: require("./../assets/images/g3.png"),
    g4: require("./../assets/images/g4.png"),
    GCenter: require("./../assets/images/GCenter.png"),

    AboutUsHero: require("./../assets/images/aboutus_herosec.jpg"),
    AvoutUsEstimate: require("./../assets/images/estimate.jpg"),
    BecomeAHomeHero: require("./../assets/images/becomeahome_hero.jpg"),

    CatWindow: require("./../assets/images/category/Window_HeroBanner.jpg"),
    CatRoofing: require("./../assets/images/category/Roofing_HeroBanner.jpg"),
    CatBathrooms: require("./../assets/images/category/Bathroom_HeroBanner.jpg"),
    CatSolar: require("./../assets/images/category/Solar_HeroBanner.jpg"),
    
    CatWindowQuote: require("./../assets/images/category/Window_Quote.jpg"),
    CatRoofingQuote: require("./../assets/images/category/Roofing_Quote.jpg"),
    CatBathroomsQuote: require("./../assets/images/category/Bathroom_Quote.jpg"),
    CatSolarQuote: require("./../assets/images/category/Solar_Quote.jpg"),
};
