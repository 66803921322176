import React from "react";
import { Qoute } from "../Icons";

export function TestSlideItem(props) {
    return (
        <>
            <div data-scroll-section data-inview="false" className="image_item">
                <div className="image_wrap">
                    <img src={props.image} alt="" />
                </div>
            </div>
            <div className="slider-item">
                <div className="testimonial-card">
                    <div className="testimonial-qoute">
                        <Qoute />
                    </div>
                    <div className="testimonial-text">
                        <p>{props.quote}</p>
                    </div>
                    <div className="testimonial-profile">
                        <div className="user-img">
                            <img src={props.userImage} alt={props.userName} />
                        </div>
                        <div className="aboutuser">
                            <p>{props.userName}</p>
                            <span>{props.userRole}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
