import React, { useEffect } from "react";
import Nav from "../../components/Nav/Nav";
import Screen_Resize from "../../config/ScreenResize";
import Pagefooter from "../../components/Pagefooter/Pagefooter";
import PageHeroSec from "../../components/PageHeroSec/PageHeroSec";
import { images } from "../../config/images";
import Benifits from "../../components/Benifits/Benifits";
import Pronetwork from "../../components/Pronetwork/Pronetwork";
import FAQ from "../../components/FAQ/FAQ";
import HappensNext from "../../components/HappensNext/HappensNext";
import JoinNetwork from "../../components/JoinNetwork/JoinNetwork";

function BecomeAHomePro() {
    useEffect(() => {
        Screen_Resize();
    }, []);

    return (
        <>
            <Nav />
            <div className="MainBody">
                <PageHeroSec
                    label="Join our Home Pro Network"
                    title="We are #1 employer among homeimprovment companies!"
                    line1="We'll connect you with homeowners looking to hire Pros in your area. Customers trust Helpful Home Pros to deliver an exceptional experience with superior professionalism and craftsmanship."
                    ImgUrl={images.BecomeAHomeHero}
                    ImgAlt="Helpful Homes"
                />
                <Benifits />
                <HappensNext />
                <FAQ />
                <Pronetwork />
                <JoinNetwork />
                <Pagefooter />
            </div>
        </>
    );
}

export default BecomeAHomePro;
