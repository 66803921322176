import React from "react";
import { customAnimation } from "../../config/animation";
import Reveal from "react-awesome-reveal";

function AboutSecContent() {
    return (
        <div className="AboutSecContent">
            <div className="h-container">
                <Reveal duration={800} keyframes={customAnimation}>
                    <div className="CardBody">
                        <div className="secLeft">
                            <h4 className="title">
                                It’s never been easier to get home repairs,
                                improvements, or just to save money!
                            </h4>
                        </div>
                        <div className="secRight">
                            <p>
                                We combine serious homeowners who are looking
                                for various energy savings, comfort or property
                                value improvements and more and connect them
                                with trustworthy, licensed contractors.
                            </p>
                        </div>
                    </div>
                </Reveal>
            </div>
        </div>
    );
}

export default AboutSecContent;
