import React, { useEffect } from "react";
import Nav from "../../components/Nav/Nav";
import Screen_Resize from "../../config/ScreenResize";
import Pagefooter from "../../components/Pagefooter/Pagefooter";
import Pronetwork from "../../components/Pronetwork/Pronetwork";
import Abouthomepros from "../../components/Abouthomepros/Abouthomepros";
import FAQ from "../../components/FAQ/FAQ";
import Beforeafterslider from "../../components/Beforeafterslider/Beforeafterslider";
import CatBenifits from "../../components/CatBenifits/CatBenifits";
import CatHeroSection from "../../components/CatHeroSection/CatHeroSection";
import CatQuote from "../../components/CatQuote/CatQuote";
import { images } from "../../config/images";
import Howwehelp from "../../components/Howwehelp/Howwehelp";

const WindowPage = () => {
    useEffect(() => {
        Screen_Resize();
    }, []);

    return (
        <>
            <Nav />
            <div className="MainBody">
                <CatHeroSection
                    title="Window installation Quotes in Minutes!"
                    line1="Instantly connect with professionals for an accurate estimate on your schedule"
                    ButtonUrl="/windows/form"
                    ImgUrl={images.CatWindow}
                />
                <CatBenifits page="window" />
                <Beforeafterslider className="category" />
                <Howwehelp url="/windows/form" />
                <Abouthomepros className="category" />
                <CatQuote
                    title="Window installation Quotes in Minutes!"
                    description="Instantly connect with professionals for an accurate estimate on your schedule"
                    ButtonUrl="/windows/form"
                    ImgUrl={images.CatWindowQuote}
                    ImgAlt="Window Image"
                />
                <FAQ />
                <Pronetwork />
                <Pagefooter />
            </div>
        </>
    );
};

export default WindowPage;
