import React from "react";
import { customAnimation } from "../../config/animation";
import Reveal from "react-awesome-reveal";
import { BlockItem } from "../common/BlockItem";
import Lightning from "../Icons/Lightning";
import Sparkles from "../Icons/Sparkles";
import Logoff from "../Icons/Logoff";
import LocationMarker from "../Icons/LocationMarker";

function Benifits() {
    return (
        <div className="Benifits">
            <div className="h-container">
                <Reveal duration={800} keyframes={customAnimation}>
                    <div className="secHeading">
                        <h2>Your benefit to work with us</h2>
                    </div>
                </Reveal>
                <div className="CardBody">
                    <Reveal duration={800} keyframes={customAnimation}>
                        <BlockItem
                            svg={
                                <>
                                    <Sparkles />
                                </>
                            }
                            title="Get more jobs"
                            description="Home improvements are a homeowners’ biggest defence against weather, natural disaster, insurance claims, and other factors (such as the age of the home) that degrade value."
                        />
                        <BlockItem
                            svg={
                                <>
                                    <Lightning />
                                </>
                            }
                            title="Get quick payments"
                            description="We can also add additional rooms, space, and other special features or even aspects that help those with special disabilities or needs."
                        />
                        <BlockItem
                            svg={
                                <>
                                    <Logoff />
                                </>
                            }
                            title="Stremline operations"
                            description="Outdoor improvements also improve the value of a home - landscaping in particular. Outdoor siding typically impacts the costs you spend on utilities, so these repairs typically pay for themselves."
                        />
                        <BlockItem
                            svg={
                                <>
                                    <LocationMarker />
                                </>
                            }
                            title="Get amazing results"
                            description="New, state-of-the-art appliances and updates address maintenance issues, improve the value of a home, and even improve efficiency, which saves you money."
                        />
                    </Reveal>
                </div>
            </div>
        </div>
    );
}

export default Benifits;
