import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import BackArwDark from "../Icons/BackArwDark";
import Reveal from "react-awesome-reveal";
import { customAnimation } from "../../config/animation";
import {
    SolarFormStep1,
    SolarFormStep2,
    SolarFormStep3,
    SolarFormStep4,
    SolarFormStep5,
    SolarFormStep6,
    SolarFormStep7,
} from "./SolarForms";
import Lock from "../Icons/Lock";

function SolarQuiz() {
    const history = useHistory();

    useEffect(() => {
        // Body Class for Page
        const body = document.querySelector("body");
        document.body.classList.add("FormPage");
        return () => {
            body.classList.remove("FormPage");
        };
    }, []);

    const [page, setPage] = useState(0);

    const onNextClick = () => {
        if (page === formTitles.length - 1) {
            history.push("/thankyou");
        } else {
            setPage((currPage) => currPage + 1);
        }
    };

    const formTitles = [
        "How many windows do you need?",
        "What can we help you with?",
        "Do you own your home?",
        "When do you want to start?",
        "Who is this quote for?",
        "%FirstName%, Your quote is almost ready!",
        "Enter your address to find the best deals near %City%",
    ];

    const FormDisplay = () => {
        if (page === 0) {
            return <SolarFormStep1 onNextClick={onNextClick} />;
        } else if (page === 1) {
            return <SolarFormStep2 onNextClick={onNextClick} />;
        } else if (page === 2) {
            return <SolarFormStep3 onNextClick={onNextClick} />;
        } else if (page === 3) {
            return <SolarFormStep4 onNextClick={onNextClick} />;
        } else if (page === 4) {
            return <SolarFormStep5 onNextClick={onNextClick} />;
        } else if (page === 5) {
            return <SolarFormStep6 onNextClick={onNextClick} />;
        } else {
            return <SolarFormStep7 onNextClick={onNextClick} />;
        }
    };

    return (
        <div className="QuizWrapper">
            <div className="h-container">
                <Reveal duration={800} keyframes={customAnimation}>
                    <div className="QuizArea">
                        <div className="QuizHeading">
                            <h4>
                                <button
                                    type="button"
                                    disabled={page === 0}
                                    onClick={() => {
                                        setPage((currPage) => currPage - 1);
                                    }}
                                >
                                    <BackArwDark />
                                </button>
                                Solar Quotes
                                <span className="formstep">
                                    {page === 0
                                        ? "1"
                                        : page === 1
                                        ? "2"
                                        : page === 2
                                        ? "3"
                                        : page === 3
                                        ? "4"
                                        : page === 4
                                        ? "5"
                                        : page === 5
                                        ? "6"
                                        : "7"}{" "}
                                    / 7
                                </span>
                            </h4>
                            <div className="progress">
                                <div
                                    className="status"
                                    style={{
                                        width:
                                            page === 0
                                                ? "14.28%"
                                                : page === 1
                                                ? "28.56%"
                                                : page === 2
                                                ? "42.84%"
                                                : page === 3
                                                ? "57.12%"
                                                : page === 4
                                                ? "71.4%"
                                                : page === 5
                                                ? "85.68%"
                                                : "100%",
                                    }}
                                ></div>
                            </div>
                        </div>
                        <div className="QuizBlock">
                            <div className="BlockContent">
                                <h3 className="title">{formTitles[page]}</h3>
                                {FormDisplay()}
                            </div>
                            <div className="BlockFooter">
                                {page < 4 ? (
                                    ""
                                ) : (
                                    <button
                                        className="primary-btn full filled"
                                        onClick={() => {
                                            if (
                                                page ===
                                                formTitles.length - 1
                                            ) {
                                                console.log(
                                                    "Window = FORM SUBMITTED"
                                                );
                                                history.push("/thankyou");
                                            } else {
                                                setPage(
                                                    (currPage) => currPage + 1
                                                );
                                            }
                                        }}
                                    >
                                        {page === formTitles.length - 1
                                            ? "Next"
                                            : "Continue"}
                                    </button>
                                )}

                                <p className="info">
                                    <Lock />
                                    Your information is safe
                                </p>
                            </div>
                        </div>
                    </div>
                </Reveal>
            </div>
        </div>
    );
}

export default SolarQuiz;
