import React from "react";

function GrowGreen() {
    return (
        <svg width="27" height="16" viewBox="0 0 27 16" fill="none">
            <path
                d="M14.8324 1.33325H25.499M25.499 1.33325V11.9999M25.499 1.33325L14.8324 11.9999L9.49902 6.66658L1.49902 14.6666"
                stroke="#1CCA96"
                strokeWidth="2.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default GrowGreen;
