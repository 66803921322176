import React from "react";

function MenuClose(props) {
    return (
        <svg className={`${props.className}`} width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path
                fillRule="evenodd"
                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
            />
            <path
                fillRule="evenodd"
                d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
            />
        </svg>
    );
}

export default MenuClose;
