import React from "react";
import { customAnimation } from "../../config/animation";
import Reveal from "react-awesome-reveal";
import { StepsItem } from "./StepsItem";
import { images } from "../../config/images";

function PriceEstimate() {
    return (
        <div className="PriceEstimate">
            <div className="h-container">
                <Reveal duration={800} keyframes={customAnimation}>
                    <div className="secHeading">
                        <h2>Price Estimate</h2>
                        <p>
                            Our contractors can even help you answer the
                            question “how much do home improvements cost?”
                        </p>
                    </div>
                </Reveal>
                <div className="CardBody">
                    <Reveal duration={800} keyframes={customAnimation}>
                        <div className="StepsWrap">
                            <h2>We Do This By:</h2>

                            <StepsItem
                                heading="Step 1"
                                title="Collecting your info"
                                description="Collecting information about you, including your phone number, email, and home address."
                            />

                            <StepsItem
                                heading="Step 2"
                                title="Discovering the project"
                                description="Gathering information about the project you need to be completed."
                            />

                            <StepsItem
                                heading="Step 3"
                                title="Understanding the timeframe"
                                description="Understanding the timeframe you’re requesting regarding the scope of your project."
                            />
                        </div>
                        <div className="secRight">
                            <div>
                                <p>
                                    After collecting this information we put
                                    together quotes with our contractor clients
                                    to help you get all the details pertaining
                                    to your project.
                                </p>

                                <p>
                                    They’ll help you figure out what costs
                                    you’ll be dealing with, including the costs
                                    of material, labour, installation and more.
                                </p>

                                <p>
                                    They’ll also help you figure out what
                                    rebates, promotions, and discounts you might
                                    qualify for. It’s seriously that easy!
                                </p>
                            </div>
                            <div className="WrapImg">
                                <img src={images.AvoutUsEstimate} alt="Helpful Homes" />
                            </div>
                        </div>
                    </Reveal>
                </div>
            </div>
        </div>
    );
}

export default PriceEstimate;
