import React from "react";
import { customAnimation } from "../../config/animation";
import Reveal from "react-awesome-reveal";
import { BlockItem } from "../common/BlockItem";
import Earth from "../Icons/Earth";
import House from "../Icons/House";
import Shield from "../Icons/Shield";
import Users from "../Icons/Users";

function WhyUs() {
    return (
        <div className="WhyUs">
            <div className="h-container">
                <Reveal duration={800} keyframes={customAnimation}>
                    <div className="secHeading">
                        <h2>Why us?</h2>
                    </div>
                </Reveal>
                <div className="CardBody">
                    <Reveal duration={800} keyframes={customAnimation}>
                        <BlockItem
                            svg={
                                <>
                                    <Shield />
                                </>
                            }
                            title="Defense"
                            description="Home improvements are a homeowners’ biggest defence against weather, natural disaster, insurance claims, and other factors (such as the age of the home) that degrade value."
                        />
                        <BlockItem
                            svg={
                                <>
                                    <Users />
                                </>
                            }
                            title="Care about others"
                            description="We can also add additional rooms, space, and other special features or even aspects that help those with special disabilities or needs."
                        />
                        <BlockItem
                            svg={
                                <>
                                    <Earth />
                                </>
                            }
                            title="Outdoor improvements"
                            description="Outdoor improvements also improve the value of a home - landscaping in particular. Outdoor siding typically impacts the costs you spend on utilities, so these repairs typically pay for themselves."
                        />
                        <BlockItem
                            svg={
                                <>
                                    <House />
                                </>
                            }
                            title="State-of-the-art appliances"
                            description="New, state-of-the-art appliances and updates address maintenance issues, improve the value of a home, and even improve efficiency, which saves you money."
                        />
                    </Reveal>
                </div>
            </div>
        </div>
    );
}

export default WhyUs;
