import React from "react";

function Lightning() {
    return (
        <svg width="24" height="28" viewBox="0 0 24 28" fill="none">
            <path
                d="M13.334 2V11.3333H22.6673L10.6673 26V16.6667H1.33398L13.334 2Z"
                stroke="#03093D"
                strokeWidth="2.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Lightning;
