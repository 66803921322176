import React from "react";
import { customAnimation } from "../../config/animation";
import Reveal from "react-awesome-reveal";
import { RightArrow } from "../Icons";
import { useHistory } from "react-router-dom";

function CatHeroSection(props) {
    const history = useHistory();

    return (
        <div className="CatHeroSection">
            <div className="h-container">
                <Reveal duration={800} keyframes={customAnimation}>
                    <div className="CardBody">
                        <div className="secContent">
                            <h3 className="title">{props.title}</h3>
                            <p className="mb-3">{props.line1}</p>
                            <button
                                type="button"
                                className="primary-btn big filled"
                                onClick={() => {
                                    history.push(props.ButtonUrl);
                                }}
                            >
                                <RightArrow /> Get Free Quote
                            </button>
                        </div>
                        <div className="secImg">
                            <div className="WrapImg">
                                <img src={props.ImgUrl} alt={props.ImgAlt} />
                            </div>
                        </div>
                    </div>
                </Reveal>
            </div>
        </div>
    );
}

export default CatHeroSection;
