import React from "react";
import { NavLink } from "react-router-dom";

export function FooterLinkItems(props) {
    return (
        <>
            <li className={`${props.className}`}>
                <NavLink to={props.link}>
                    {props.text}
                </NavLink>
            </li>
        </>
    );
}
