import React from "react";
import IconPencil from "../Icons/IconPencil";
import IconPhone from "../Icons/IconPhone";
import IconSparkles from "../Icons/IconSparkles";
import StatusCheck from "../Icons/StatusCheck";
import { NavLink } from "react-router-dom";

function ThankYouPage() {
    return (
        <div className="QuizSuccess">
            <div className="QuizStatus">
                <StatusCheck />
            </div>
            <h3>Thank you for request! We will contact you soon!</h3>

            <div className="CardBody">
                <div className="QuizMsg">
                    <IconPencil />
                    <h6>Congratulations!</h6>
                    <p>
                        You are one step closer to completing your project! A
                        courteous expert will confirm your information and
                        review you eligibility.
                    </p>
                </div>
                <div className="QuizMsg">
                    <IconPhone />
                    <h6>Answer your phone!</h6>
                    <p>
                        The main goal is to ensure you’re comfortable with an
                        expert we’ve matched you with. You will be contacted by
                        a courteous expert, so get ready.
                    </p>
                </div>
                <div className="QuizMsg">
                    <IconSparkles />
                    <h6>Grab a pen!</h6>
                    <p>
                        You’re moments away from obtaining information about
                        your project. When a courteous expert calls make sure to
                        write down their quotes and ask any questions you may
                        have.
                    </p>
                </div>
            </div>

            <NavLink
                className="primary-btn full filled mt-5 uppercase mx-auto max-w-xs"
                to="/home"
            >
                Back to Homepage
            </NavLink>
        </div>
    );
}

export default ThankYouPage;
