import React from "react";

function IconSparkles() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M6.66667 4V9.33333V4ZM4 6.66667H9.33333H4ZM8 22.6667V28V22.6667ZM5.33333 25.3333H10.6667H5.33333ZM17.3333 4L20.3813 13.1427L28 16L20.3813 18.8573L17.3333 28L14.2853 18.8573L6.66667 16L14.2853 13.1427L17.3333 4Z"
                stroke="#03093D"
                strokeWidth="2.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default IconSparkles;
