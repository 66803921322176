import React, { useState } from "react";

export function BAPSlide(props) {
    console.log("BAPSlide props", props);
    const [show, setShow] = useState(true);

    const HideShow = () => {
        setShow(!show);
        console.log("hide show");
    };

    return (
        <div className="slider-item" onClick={props.onClick}>
            <div className="BAPBlock">
                <div className="SwitchContainer">
                    <label className="BtnSwitch">
                        <input
                            type="checkbox"
                            id="colorMode"
                            value="1"
                            onClick={HideShow}
                        />
                        <label
                            htmlFor="colorMode"
                            data-on="After"
                            data-off="Before"
                            className="BtnSwitchInner"
                        ></label>
                    </label>
                </div>
                <div className="BAPImg">
                    {show ? (
                        <div className="BAPImg-Before">
                            <div
                                className="ImageWrapper"
                                style={{
                                    backgroundImage: `url(${props.beforeImg})`,
                                }}
                            ></div>
                        </div>
                    ) : (
                        <div className="BAPImg-After">
                            <div
                                className="ImageWrapper"
                                style={{
                                    backgroundImage: `url(${props.afterImg})`,
                                }}
                            ></div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
