import React from "react";
import { NavLink } from "react-router-dom";

export function NavItems(props) {
    return (
        <li className={`nav-item ${props.className}`}>
            <NavLink to={props.link} className="nav-link" title={props.title}>
                {props.text}
            </NavLink>
        </li>
    );
}
