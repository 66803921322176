import Home from "../pages/Home/Home";
import AboutUs from "../pages/AboutUs/AboutUs";
import BecomeAHomePro from "../pages/BecomeAHomePro/BecomeAHomePro";

import WindowsForm from "../pages/WindowsForm/WindowsForm";
import RoofingForm from "../pages/RoofingForm/RoofingForm";
import BathroomForm from "../pages/BathroomForm/BathroomForm";
import SolarForm from "../pages/SolarForm/SolarForm";

import BathroomPage from "../pages/BathroomPage/BathroomPage";
import WindowPage from "../pages/WindowPage/WindowPage";
import RoofingPage from "../pages/RoofingPage/RoofingPage";
import SolarPage from "../pages/SolarPage/SolarPage";
import ThankYou from "../pages/ThankYou/ThankYou";

const routes = [
    {
        path: "/",
        exact: true,
        component: Home,
    },
    {
        path: "/home",
        exact: true,
        component: Home,
    },
    {
        path: "/thankyou",
        exact: true,
        component: ThankYou,
    },
    {
        path: "/windows",
        exact: true,
        component: WindowPage,
    },
    {
        path: "/windows/form",
        exact: true,
        component: WindowsForm,
    },
    {
        path: "/roofing",
        exact: true,
        component: RoofingPage,
    },
    {
        path: "/roofing/form",
        exact: true,
        component: RoofingForm,
    },
    {
        path: "/bathroom",
        exact: true,
        component: BathroomPage,
    },
    {
        path: "/bathroom/form",
        exact: true,
        component: BathroomForm,
    },
    {
        path: "/solar",
        exact: true,
        component: SolarPage,
    },
    {
        path: "/solar/form",
        exact: true,
        component: SolarForm,
    },
    {
        path: "/aboutus",
        exact: true,
        component: AboutUs,
    },
    {
        path: "/becomeahomepro",
        exact: true,
        component: BecomeAHomePro,
    },
];

export default routes;
