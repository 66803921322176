import React from "react";

function BlueBoxItem(Props) {
    return (
        <div className="BlueBoxItem">
            <div className="numberTag"> {Props.count} </div>
            <div className="hekpText">
                <h4 className="h4">{Props.helpTitle}</h4>
                <p>{Props.description}</p>
            </div>
        </div>
    );
}

export default BlueBoxItem;
