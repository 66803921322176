import React from "react";

function BtnCheckIcon() {
    return (
        <svg width="17" height="12" viewBox="0 0 17 12" fill="none">
            <path
                d="M6.10049 9.8103L5.74693 9.45674L1.79881 5.50862C1.66709 5.38276 1.49143 5.31318 1.30915 5.31476C1.12561 5.31636 0.950045 5.38997 0.82026 5.51976C0.690474 5.64955 0.616856 5.82511 0.615261 6.00865C0.613677 6.19093 0.683257 6.36658 0.809116 6.49831L5.60559 11.2948C5.60561 11.2948 5.60562 11.2948 5.60564 11.2948C5.73691 11.426 5.9149 11.4997 6.10049 11.4997C6.28608 11.4997 6.46407 11.426 6.59533 11.2948L6.59539 11.2948M6.10049 9.8103L6.94889 11.6484L6.59539 11.2948M6.10049 9.8103L6.45404 9.45674L15.2056 0.705195L14.8521 0.351588L15.2056 0.705141C15.3369 0.573945 15.5149 0.500244 15.7005 0.500244C15.8861 0.500244 16.064 0.573925 16.1953 0.705088M6.10049 9.8103L16.1953 1.69483C16.3265 1.56357 16.4002 1.38558 16.4002 1.19999C16.4002 1.01442 16.3265 0.836453 16.1954 0.705195M6.59539 11.2948L16.1953 1.69489L6.59539 11.2948ZM16.1954 0.705195L16.5489 0.351605L16.1953 0.705088M16.1954 0.705195C16.1954 0.705159 16.1953 0.705124 16.1953 0.705088M16.1954 0.705195C16.1954 0.705159 16.1953 0.705124 16.1953 0.705088"
                fill="white"
                stroke="white"
            />
        </svg>
    );
}

export default BtnCheckIcon;
