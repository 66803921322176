import React from "react";
import { RightArrow } from "../../components/Icons/index";
import { NavLink } from "react-router-dom";

function Helpcard(props) {
    return (
        <NavLink to={props.link} className="Helpcard">
            <div className="CardBoxShadow"></div>
            <div className="CardBoxContent">
                <h4 className="h4 text-white">{props.cardName}</h4>
                <div className="bottomImg">
                    <img src={props.images} alt={props.cardName} />
                    <div className="overlay"></div>
                </div>
                <span className="clickArrow">
                    <RightArrow />
                </span>
            </div>
        </NavLink>
    );
}

export default Helpcard;
