import React from "react";
import Bannercard from "../Bannercard/Bannercard";
import { images } from "../../config/images";
import { customAnimation } from "../../config/animation";
import Reveal, { Fade } from "react-awesome-reveal";

function Homebanner(props) {
    return (
        <div className="homeBanner">
            <div className="h-container">
                <div className="bannerBody">
                    <div className="BannerText">
                        <Fade delay={500}>
                            <h1 className="mb-3 lg:mb-20">
                                We Help Anyone Find <span>Top-end</span>{" "}
                                Contractors for Virtually Any Project!
                            </h1>
                            <p className="h5">What can we help you with?</p>
                        </Fade>
                    </div>
                    <div className="CardBody grid grid-cols-2  lg:grid-cols-4 lg:gap-6 gap-4">
                        <Reveal duration={800} keyframes={customAnimation}>
                            <Bannercard
                                servicename={"Windows"}
                                link="/windows"
                                images={images.Service1}
                            />
                            <Bannercard
                                servicename={"Roofing"}
                                link="/roofing"
                                images={images.Service2}
                            />
                            <Bannercard
                                servicename={"Bathroom"}
                                link="/bathroom"
                                images={images.Service3}
                            />
                            <Bannercard
                                servicename={"Solar"}
                                link="/solar"
                                images={images.Service4}
                            />
                        </Reveal>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Homebanner;
