import React from "react";

function Pointer() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
            <path
                d="M14.0003 14L12.0003 19L8.00028 8.00001L19.0003 12L14.0003 14ZM14.0003 14L19.0003 19M6.18828 1.23901L6.96528 4.13601M4.13628 6.96501L1.23828 6.18801M12.9503 3.05001L10.8283 5.17201M5.17128 10.828L3.05128 12.95"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Pointer;
