import React from "react";

function Shield() {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none">
            <path
                d="M12.5 16L15.1667 18.6667L20.5 13.3334M27.9907 7.97869C23.7748 8.20257 19.6423 6.74484 16.5 3.92535C13.3577 6.74484 9.2252 8.20257 5.00934 7.97869C4.67005 9.29217 4.4989 10.6434 4.50001 12C4.50001 19.4547 9.59867 25.72 16.5 27.496C23.4013 25.72 28.5 19.456 28.5 12C28.5 10.6107 28.3227 9.26402 27.9907 7.97869Z"
                stroke="#03093D"
                strokeWidth="2.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Shield;
