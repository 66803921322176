import React from "react";
import { RightArrow } from "../Icons/index";
import { NavLink } from "react-router-dom";

function Bannercard(props) {
    return (
        <NavLink to={props.link} className="CardBox">
            <div className="CardBoxShadow"></div>
            <div className="CardBoxContent">
                <h4 className="h4 text-white">{props.servicename}</h4>
                <div className="bottomImg">
                    <img src={props.images} alt={props.servicename} />
                </div>
                <span className="clickArrow">
                    <RightArrow />
                </span>
            </div>
        </NavLink>
    );
}

export default Bannercard;
