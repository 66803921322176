import React from "react";
import { images } from "../../config/images";
import { Fade } from "react-awesome-reveal";
import { NavItems } from "./NavItems";
import MobileMenu from "../Icons/MobileMenu";
import MenuClose from "../Icons/MenuClose";

function Nav() {
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [header, setHeader] = React.useState(false);

    const FixedHeaderonScroll = () => {
        console.log("scroll");
        if (window.scrollY >= 80) {
            setHeader(true);
        } else {
            setHeader(false);
        }
    };

    window.addEventListener("scroll", FixedHeaderonScroll);

    return (
        <>
            <div className={header ? "MainHeader Sticky" : "MainHeader"}>
                <div className="w-full ">
                    <nav className="navbar">
                        <div className="h-container flex flex-wrap items-center justify-between">
                            <div className="w-full relative flex justify-between items-center lg:w-auto lg:pr-4 lg:static lg:block lg:justify-start">
                                <Fade delay={100} direction="down">
                                    <a className="logoLink" href="/">
                                        <img
                                            src={images.logoMain}
                                            alt="Helpful Homes"
                                            className="LogoLight"
                                        />
                                        <img
                                            src={images.Logomobile}
                                            alt="Helpful Homes"
                                            className="LogoDark"
                                        />
                                    </a>
                                </Fade>
                                <button
                                    className="MenuToggle"
                                    type="button"
                                    onClick={() => setMenuOpen(!menuOpen)}
                                >
                                    <span className="Text">Menu</span>
                                    {menuOpen ? (
                                        <MenuClose />
                                    ) : (
                                        <MobileMenu />
                                    )}
                                </button>
                            </div>
                            <div
                                className={
                                    "NavWrap" + (menuOpen ? " flex" : " hidden")
                                }
                            >
                                <Fade delay={100} direction="down">
                                    <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                                        <NavItems
                                            text="Windows"
                                            link="/windows"
                                            title="Windows"
                                        ></NavItems>
                                        <NavItems
                                            text="Roofing"
                                            link="/roofing"
                                            title="Roofing"
                                        ></NavItems>
                                        <NavItems
                                            text="Solar"
                                            link="/solar"
                                            title="Solar"
                                        ></NavItems>
                                        <NavItems
                                            text="Bathroom"
                                            link="/bathroom"
                                            title="Bathroom"
                                        ></NavItems>
                                        <NavItems
                                            text="About us"
                                            link="/aboutus"
                                            title="About us"
                                        ></NavItems>
                                        <NavItems
                                            text="Become a Home Pro"
                                            link="/becomeahomepro"
                                            title="Become a Home Pro"
                                        ></NavItems>
                                    </ul>
                                </Fade>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    );
}

export default Nav;
