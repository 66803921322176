import React from "react";

export function BlockItem(props) {
    return (
        <>
            <div className="ItemWrap">
                {props.svg}
                <h4>{props.title}</h4>
                <p>{props.description}</p>
            </div>
        </>
    );
}
