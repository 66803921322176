import React from "react";

function House() {
    return (
        <svg width="27" height="28" viewBox="0 0 27 28" fill="none">
            <path
                d="M1.5 14L4.16667 11.3333M4.16667 11.3333L13.5 2L22.8333 11.3333M4.16667 11.3333V24.6667C4.16667 25.0203 4.30714 25.3594 4.55719 25.6095C4.80724 25.8595 5.14638 26 5.5 26H9.5M22.8333 11.3333L25.5 14M22.8333 11.3333V24.6667C22.8333 25.0203 22.6929 25.3594 22.4428 25.6095C22.1928 25.8595 21.8536 26 21.5 26H17.5M9.5 26C9.85362 26 10.1928 25.8595 10.4428 25.6095C10.6929 25.3594 10.8333 25.0203 10.8333 24.6667V19.3333C10.8333 18.9797 10.9738 18.6406 11.2239 18.3905C11.4739 18.1405 11.813 18 12.1667 18H14.8333C15.187 18 15.5261 18.1405 15.7761 18.3905C16.0262 18.6406 16.1667 18.9797 16.1667 19.3333V24.6667C16.1667 25.0203 16.3071 25.3594 16.5572 25.6095C16.8072 25.8595 17.1464 26 17.5 26M9.5 26H17.5"
                stroke="#03093D"
                strokeWidth="2.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default House;
