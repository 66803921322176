import React from "react";
import Modal from "react-modal";
import CloseWhite from "../Icons/CloseWhite";
import Slider from "react-slick";
import WhiteNextArrow from "../Icons/WhiteNextArrow";
import WhitePrevArrow from "../Icons/WhitePrevArrow";
import { BAPSlide } from "./BAPSlide";
import { images } from "../../config/images";

function SlideNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <WhiteNextArrow />
        </div>
    );
}

function SlidePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <WhitePrevArrow />
        </div>
    );
}

function BeforeAfterPopup({ closeModal, modalIsOpen }) {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SlideNextArrow />,
        prevArrow: <SlidePrevArrow />,
    };

    return (
        <div className="BeforeAfterPopup">
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Join Our Home Pro Network"
                overlayClassName="ModalSldier"
            >
                <button className="CloseModal" onClick={closeModal}>
                    <CloseWhite />
                </button>
                <Slider {...settings}>
                    <BAPSlide
                        beforeImg={images.beforeimg}
                        beforeImgName="Helpful Homes"
                        afterImg={images.afterimg}
                        afterImgName="Helpful Homes"
                    />

                    <BAPSlide
                        beforeImg={images.beforeimg}
                        beforeImgName="Helpful Homes"
                        afterImg={images.afterimg}
                        afterImgName="Helpful Homes"
                    />

                    <BAPSlide
                        beforeImg={images.beforeimg}
                        beforeImgName="Helpful Homes"
                        afterImg={images.afterimg}
                        afterImgName="Helpful Homes"
                    />
                </Slider>
            </Modal>
        </div>
    );
}

export default BeforeAfterPopup;
