import React from "react";
import { customAnimation } from "../../config/animation";
import Reveal from "react-awesome-reveal";
import BlueBoxItem from "../common/BlueBoxItem/BlueBoxItem";
import { RightBtnArrow } from "../Icons/index";
import { useHistory } from "react-router-dom";

function Howwehelp(props) {
    const history = useHistory();

    return (
        <div className="howweHelp">
            <div className="h-container">
                <div className="helpBlocks">
                    <Reveal duration={800} keyframes={customAnimation}>
                        <div className="PageTile">
                            <h2 className="h2 text-center lg:text-left">
                                How we help you:
                            </h2>
                            <div className="lg:block hidden">
                                <button
                                    className="primary-btn big ghost btn-white filled"
                                    type="button"
                                    onClick={() => {
                                        history.push(props.url);
                                    }}
                                >
                                    <RightBtnArrow /> GET FREE QUOTE
                                </button>
                            </div>
                        </div>
                    </Reveal>

                    <Reveal duration={800} keyframes={customAnimation}>
                        <div className="CardBody grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 xl:gap-20 pt-3 lg:pt-0">
                            <BlueBoxItem
                                count={"1"}
                                helpTitle={"Tell us your needs"}
                                description={
                                    "We collect information about your location, your project, and your home improvement needs."
                                }
                            />
                            <BlueBoxItem
                                count={"2"}
                                helpTitle={"We help you find contractors"}
                                description={
                                    "Our expansive contractor network works with you to give personalized pricing quotes."
                                }
                            />
                            <BlueBoxItem
                                count={"3"}
                                helpTitle={"Schedule an appointment"}
                                description={
                                    "Book an appointment with a qualified contractor to review project details."
                                }
                            />
                            <BlueBoxItem
                                count={"4"}
                                helpTitle={"Seamless home improvement"}
                                description={
                                    "Sit back and relax with confidence. A pro will arrive to get started on your project."
                                }
                            />
                        </div>
                        <div className="block lg:hidden pl-6 mt-10">
                            <button
                                className="primary-btn big ghost btn-white filled"
                                type="button"
                                onClick={() => {
                                    history.push(props.url);
                                }}
                            >
                                <RightBtnArrow /> Take a survey
                            </button>
                        </div>
                    </Reveal>
                </div>
            </div>
        </div>
    );
}

export default Howwehelp;
