import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TestSlideItem } from "./SlideItem";
import Slider from "react-slick";
import { images } from "../../config/images";
import { customAnimation } from "../../config/animation";
import {
    SliderArrowRight,
    SliderArrowLeft,
} from "../../components/Icons/index";
import Reveal, { Fade } from "react-awesome-reveal";

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <SliderArrowRight />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <SliderArrowLeft />
        </div>
    );
}

function Testimonial() {
    let settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="testimonialblock">
            <div className="h-container">
                <Reveal duration={800} keyframes={customAnimation}>
                    <div className="PageTile">
                        <h2 className="h2 text-center lg:text-left">
                            Testimonials
                        </h2>
                    </div>
                </Reveal>
                <div className="testimonial-wrapper">
                    <Fade>
                        <div className="testimonial-slider">
                            <Slider {...settings} className="TestimonialSlider">
                                <TestSlideItem
                                    quote="I had 12 windows replaced last year. The whole process, from discussing requirements and placing the order through to installation was seamless. I am very pleased with everything and the windows look fantastic."
                                    userImage={images.User}
                                    userName="Jennifer Simpson"
                                    userRole="customer"
                                ></TestSlideItem>
                                <TestSlideItem
                                    quote="I had 12 windows replaced last year. The whole process, from discussing requirements and placing the order through to installation was seamless. I am very pleased with everything and the windows look fantastic."
                                    userImage={images.User}
                                    userName="Jennifer Simpson 1"
                                    userRole="customer"
                                ></TestSlideItem>
                                <TestSlideItem
                                    quote="I had 12 windows replaced last year. The whole process, from discussing requirements and placing the order through to installation was seamless. I am very pleased with everything and the windows look fantastic."
                                    userImage={images.User}
                                    userName="Jennifer Simpson 2"
                                    userRole="customer"
                                ></TestSlideItem>
                                <TestSlideItem
                                    quote="I had 12 windows replaced last year. The whole process, from discussing requirements and placing the order through to installation was seamless. I am very pleased with everything and the windows look fantastic."
                                    userImage={images.User}
                                    userName="Jennifer Simpson 3"
                                    userRole="customer"
                                ></TestSlideItem>
                            </Slider>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    );
}

export default Testimonial;
