import React, { useEffect } from "react";
import Nav from "../../components/Nav/Nav";
import Screen_Resize from "../../config/ScreenResize";
import ThankYouPage from "../../components/ThankYouPage/ThankYouPage";

const ThankYou = () => {
    useEffect(() => {
        Screen_Resize();

        // Body Class for Page
        const body = document.querySelector("body");
        document.body.classList.add("FormPage");
        return () => {
            body.classList.remove("FormPage");
        };
    }, []);

    return (
        <>
            <Nav />
            <div className="MainBody">
                <div className="h-container">
                    <ThankYouPage />
                </div>
            </div>
        </>
    );
};

export default ThankYou;
