import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Reveal from "react-awesome-reveal";
import { customAnimation } from "../../config/animation";
import BackArwDark from "../Icons/BackArwDark";
import {
    RoofFormStep1,
    RoofFormStep2,
    RoofFormStep3,
    RoofFormStep4,
    RoofFormStep5,
    RoofFormStep6,
    RoofFormStep7,
    RoofFormStep8,
} from "./RoofingForms";

function RoofingQuiz() {
    const history = useHistory();

    useEffect(() => {
        // Body Class for Page
        const body = document.querySelector("body");
        document.body.classList.add("FormPage");
        return () => {
            body.classList.remove("FormPage");
        };
    }, []);

    const [page, setPage] = useState(0);

    const formTitles = [
        "Do you own your home?",
        "What is the nature of your project?",
        "How many new windows do you need for your home?",
        "What is your purchase time frame?",
        "Where is your project located?",
        "What is your name?",
        "What email is best for sending you quotes?",
        "What is the best phone number to reach you?",
    ];

    const FormDisplay = () => {
        if (page === 0) {
            return <RoofFormStep1 />;
        } else if (page === 1) {
            return <RoofFormStep2 />;
        } else if (page === 2) {
            return <RoofFormStep3 />;
        } else if (page === 3) {
            return <RoofFormStep4 />;
        } else if (page === 4) {
            return <RoofFormStep5 />;
        } else if (page === 5) {
            return <RoofFormStep6 />;
        } else if (page === 6) {
            return <RoofFormStep7 />;
        } else {
            return <RoofFormStep8 />;
        }
    };

    return (
        <div className="QuizWrapper">
            <div className="h-container">
                <Reveal duration={800} keyframes={customAnimation}>
                    <div className="QuizArea">
                        <div className="QuizHeading">
                            <h4>
                                <button
                                    type="button"
                                    disabled={page === 0}
                                    onClick={() => {
                                        setPage((currPage) => currPage - 1);
                                    }}
                                >
                                    <BackArwDark />
                                </button>
                                Roofing Quotes{" "}
                                <span className="formstep">
                                    {page === 0
                                        ? "1"
                                        : page === 1
                                        ? "2"
                                        : page === 2
                                        ? "3"
                                        : page === 3
                                        ? "4"
                                        : page === 4
                                        ? "5"
                                        : page === 5
                                        ? "6"
                                        : page === 6
                                        ? "7"
                                        : "8"}{" "}
                                    / 8
                                </span>
                            </h4>
                            <div className="progress">
                                <div
                                    className="status"
                                    style={{
                                        width:
                                            page === 0
                                                ? "12.5%"
                                                : page === 1
                                                ? "25%"
                                                : page === 2
                                                ? "37.5%"
                                                : page === 3
                                                ? "50%"
                                                : page === 4
                                                ? "62.5%"
                                                : page === 5
                                                ? "75%"
                                                : page === 6
                                                ? "87.5%"
                                                : "100%",
                                    }}
                                ></div>
                            </div>
                        </div>
                        <div className="QuizBlock">
                            <div className="BlockContent">
                                <h3 className="title">{formTitles[page]}</h3>
                                {FormDisplay()}
                            </div>
                            <div className="BlockFooter">
                                <button
                                    className="primary-btn full filled"
                                    onClick={() => {
                                        if (page === formTitles.length - 1) {
                                            console.log(
                                                "Roofing = FORM SUBMITTED"
                                            );
                                            history.push("/thankyou");
                                        } else {
                                            setPage((currPage) => currPage + 1);
                                        }
                                    }}
                                >
                                    {page === formTitles.length - 1
                                        ? "Next"
                                        : "Continue"}
                                </button>
                            </div>
                        </div>
                    </div>
                </Reveal>
            </div>
        </div>
    );
}

export default RoofingQuiz;
