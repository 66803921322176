import React from "react";

export function FormItem(props) {
    return (
        <>
            <div className={`form-item ${props.className}`}>
                <input
                    type={props.type}
                    className="form-input"
                    name={props.name}
                    placeholder={props.placeholder}
                />

                <div className="msg-error">
                    Please enter data
                </div>
            </div>
        </>
    );
}
