import { Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
import routes from "./config/routes";

function App() {
    return (
        <div className="MainWrapper">
            <Suspense fallback={<div className="app-loading">Loading...</div>}>
                <BrowserRouter>
                    <Switch>
                        {routes.map((route) => {
                            return (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    component={route.component}
                                    exact={route.exact}
                                />
                            );
                        })}
                    </Switch>
                </BrowserRouter>
            </Suspense>
        </div>
    );
}

export default App;
