import React from "react";

function Qoute() {
    return (
        <svg width="31" height="23" viewBox="0 0 31 23" fill="none">
            <path
                d="M8.304 22.32C6.24 22.32 4.464 21.528 2.976 19.944C1.536 18.312 0.816 15.984 0.816 12.96C0.816 9.12 1.8 6.144 3.768 4.032C5.784 1.872 8.616 0.791998 12.264 0.791998C13.224 0.791998 14.064 0.863997 14.784 1.008V4.968C13.68 4.824 12.792 4.752 12.12 4.752C10.104 4.752 8.544 5.376 7.44 6.624C6.336 7.728 5.712 9.096 5.568 10.728C6.24 9.912 7.296 9.504 8.736 9.504C10.512 9.504 11.976 10.128 13.128 11.376C14.28 12.576 14.856 14.088 14.856 15.912C14.856 17.784 14.232 19.32 12.984 20.52C11.784 21.72 10.224 22.32 8.304 22.32ZM24.288 22.32C22.224 22.32 20.448 21.528 18.96 19.944C17.52 18.312 16.8 15.984 16.8 12.96C16.8 9.12 17.784 6.144 19.752 4.032C21.768 1.872 24.6 0.791998 28.248 0.791998C29.208 0.791998 30.048 0.863997 30.768 1.008V4.968C29.664 4.824 28.776 4.752 28.104 4.752C26.088 4.752 24.528 5.376 23.424 6.624C22.32 7.728 21.696 9.096 21.552 10.728C22.224 9.912 23.28 9.504 24.72 9.504C26.496 9.504 27.96 10.128 29.112 11.376C30.264 12.576 30.84 14.088 30.84 15.912C30.84 17.784 30.216 19.32 28.968 20.52C27.768 21.72 26.208 22.32 24.288 22.32Z"
                fill="currentColor"
            />
        </svg>
    );
}

export default Qoute;
