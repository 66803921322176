import React from "react";
import { customAnimation } from "../../config/animation";
import Reveal from "react-awesome-reveal";
import { QAItem } from "./QAItem";

function FAQ() {
    return (
        <div className="FAQ">
            <div className="h-container">
                <Reveal duration={800} keyframes={customAnimation}>
                    <div className="secHeading">
                        <h2 className="hidden md:block">
                            Frequently Asked Questions
                        </h2>
                        <h2 className="block md:hidden">FAQ</h2>
                    </div>
                </Reveal>
                <div className="QAList">
                    <Reveal duration={800} keyframes={customAnimation}>
                        <QAItem
                            question="Do I get to speak to someone in person before accepting the estimate?"
                            answer="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
                        />
                        <QAItem
                            question="How are your walk-in tub quotes different from other companies?"
                            answer="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
                        />
                        <QAItem
                            question="What areas do you currently service?"
                            answer="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
                        />
                    </Reveal>
                </div>
            </div>
        </div>
    );
}

export default FAQ;
