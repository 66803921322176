import React from "react";

function WhiteNextArrow() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path
                d="M15 8.33337L26.6667 20L15 31.6667"
                stroke="white"
                strokeWidth="3.33"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default WhiteNextArrow;
