import React, { useEffect } from "react";
import BathroomQuiz from "../../components/BathroomQuiz/BathroomQuiz";
import Nav from "../../components/Nav/Nav";
import Screen_Resize from "../../config/ScreenResize";

const BathroomForm = () => {
    useEffect(() => {
        Screen_Resize();
    }, []);

    return (
        <>
            <Nav />
            <div className="MainBody">
                <BathroomQuiz />
            </div>
        </>
    );
};

export default BathroomForm;
