import React, { useEffect } from "react";
import Nav from "../../components/Nav/Nav";
import Screen_Resize from "../../config/ScreenResize";
import RoofingQuiz from "../../components/RoofingQuiz/RoofingQuiz";

const RoofingForm = () => {
    useEffect(() => {
        Screen_Resize();
    }, []);

    return (
        <>
            <Nav />
            <div className="MainBody">
                <RoofingQuiz />
            </div>
        </>
    );
};

export default RoofingForm;
